<template>
    <list-page-layout>
        <search-panel slot="header" @refresh="handleSearch" @search="handleSearch" />
        <container-panel>
            <template slot="title">
                风险因子列表
            </template>
            <a-button slot="button"
                      v-auth="RULE_ENGINE_ADD_RISK_FACTOR_BUTTON"
                      type="primary"
                      @click="handleAdd">
                添加风险因子
            </a-button>
            <template slot="content" slot-scope="">
                <a-table :columns="columns"
                         :data-source="dataList"
                         :pagination="pagination"
                         :loading="loading"
                         row-key="id"
                         :scroll="scrollConfig"
                         @change="handleTableChange">
                    <template slot="dataType" slot-scope="text,action">
                        <template v-if="showDetail(text)">
                            <a-button
                                v-auth="RULE_ENGINE_LOOK_RISK_FACTOR_ATTRIBUTE_BUTTON"
                                type="link"
                                @click="handleLook(action)">
                                详情
                            </a-button>
                        </template>
                        <template v-else>/</template>
                    </template>

                    <template slot="action" slot-scope="text,action">
                        <a-button
                            v-auth="RULE_ENGINE_DELETE_RISK_FACTOR_BUTTON"
                            type="link"
                            :disabled="action.state===1"
                            @click="handleDelete(action)">
                            删除
                        </a-button>
                    </template>
                </a-table>
            </template>
        </container-panel>

        <add-risk-factor-modal
            :visible="visible"
            :record="record"
            @cancel="handleCancel"
            @success="handleSuccess" />
        <attribute-modal
            :visible="attributeVisible"
            :record="attributeRecord"
            @cancel="handleAttributeCancel"
            @success="handleAttributeCancel" />
    </list-page-layout>
</template>

<script>
import { Button as AButton, Table as ATable } from 'ant-design-vue'
import {
    RULE_ENGINE_ADD_RISK_FACTOR_BUTTON, RULE_ENGINE_DELETE_RISK_FACTOR_BUTTON,
    RULE_ENGINE_LOOK_RISK_FACTOR_ATTRIBUTE_BUTTON
} from '@constant/authEnum/ruleEngine'
import AddRiskFactorModal from './AddRiskFactorModal'
import AttributeModal from './AttributeModal'
import ContainerPanel from '@components/ContainerPanel'
import ListPageLayout from '@layout/ListPageLayout'
import SearchPanel from './SearchPanel'

import { DEFAULT_PAGES_SIZE } from '@config'
import {
    RISK_FACTOR_DATA_TYPE_DICT_TYPE, RISK_FACTOR_DATA_TYPE_DICT_CASCADE_TYPE
} from '@constant/enum'
import { ERROR_COMMIT_DELETE } from '@constant/error'
import tableShowTotal from '@mixins/tableShowTotal'
import enumOperate from '@mixins/enumOperate'

import getRiskFactorListService from '@service/ruleEngine/riskFactor/getRiskFactorListService'
import deleteRiskFactorService from '@service/ruleEngine/riskFactor/deleteRistFactorService'
import { scrollConfig } from '@constant/Const'
import { actionModelConfirm } from '@utils/tip'

const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        align: 'left',
        width: 100,
        key: 'index',
        customRender: (v, r, i) => ++i
    },
    {
        title: '风险对象',
        dataIndex: 'masterDataName',
        align: 'left',
        width: 200,
        key: 'masterDataName'
    },
    {
        title: '风险分类',
        dataIndex: 'masterDataClassificationName',
        align: 'left',
        width: 200,
        key: 'masterDataClassificationName'
    },
    {
        title: '风险因子',
        dataIndex: 'factorName',
        align: 'left',
        width: 300,
        key: 'factorName'
    },
    {
        title: '属性值',
        dataIndex: 'dataType',
        align: 'left',
        width: 200,
        scopedSlots: {
            customRender: 'dataType'
        },
        key: 'dataType'
    },
    {
        title: '操作',
        key: 'action',
        align: 'left',
        width: 200,
        scopedSlots: {
            customRender: 'action'
        }
    }
]
export default {
    name: 'RiskFactor',
    components: {
        ListPageLayout,
        ContainerPanel,
        ATable,
        AButton,
        AttributeModal,
        AddRiskFactorModal,
        SearchPanel
    },
    // SearchPanel,
    mixins: [tableShowTotal, enumOperate],
    data() {
        return {
            scrollConfig,
            RULE_ENGINE_ADD_RISK_FACTOR_BUTTON,
            RULE_ENGINE_DELETE_RISK_FACTOR_BUTTON,
            RULE_ENGINE_LOOK_RISK_FACTOR_ATTRIBUTE_BUTTON,
            RISK_FACTOR_DATA_TYPE_DICT_TYPE,
            RISK_FACTOR_DATA_TYPE_DICT_CASCADE_TYPE,
            columns,
            params: {},
            dataList: [],
            limit: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            loading: false,
            visible: false,
            attributeVisible: false,
            record: {},
            attributeRecord: {}
        }
    },
    computed: {
        pagination() {
            const { total, limit, current, tableShowTotal } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        }
    },
    created() {
        this.getList()
    },
    methods: {
        showDetail(text) {
            return text === RISK_FACTOR_DATA_TYPE_DICT_TYPE || text === RISK_FACTOR_DATA_TYPE_DICT_CASCADE_TYPE
        },
        handleAdd() {
            this.visible = true
        },
        // 添加成功
        handleSuccess() {
            this.visible = false
            this.getList()
        },
        handleCancel() {
            this.visible = false
        },
        handleAttributeCancel() {
            this.attributeVisible = false
        },
        handleLook(record) {
            this.attributeVisible = true
            this.attributeRecord = record
        },
        async handleDelete(action) {
            await actionModelConfirm.call(this, '确认删除当前风险因子吗', 'danger')
            this.doDelete(action)
        },
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        async doDelete(action) {
            try {
                let { id } = action
                await deleteRiskFactorService(id)
                this.$message.success(ERROR_COMMIT_DELETE)
                this.getList()
            } catch (e) {
                this.$message.error(e.message)
            }
        },
        handleSearch(params) {
            this.params = params
            this.current = 1
            this.getList()
        },
        async getList() {
            try {
                this.loading = true
                const { current, limit, params } = this
                const param = {
                    current,
                    size: limit,
                    ...params
                }
                const result = await getRiskFactorListService(param)
                this.dataList = result.records
                this.current = result.current
                this.total = result.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
