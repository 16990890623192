<template>
    <search-panel :model="form"
                  layout="vertical"
                  class="enterprise-list-search-panel"
                  @search="handleSearch"
                  @refresh="handleRefresh">
        <form-col :span="6" label="风险因子" prop="query">
            <template slot="formElements">
                <a-input v-model.trim="form.query" placeholder="请输入风险因子" />
            </template>
        </form-col>
        <form-col :span="6" label="风险对象" prop="masterDataId">
            <risk-obj-select
                v-model="form.masterDataId"
                slot="formElements"
                @option="handleOption" />
        </form-col>
        <form-col :span="6" label="风险分类" prop="categoryArr">
            <risk-category-select
                v-model="form.categoryArr"
                slot="formElements"
                :option="masterOption"
                @change="handleChange" />
        </form-col>
    </search-panel>
</template>

<script>
import {
    Input as AInput
} from 'ant-design-vue'
import SearchPanel from '@components/SearchPanel'
import FormCol from '@components/FormCol'
import RiskObjSelect from '@weights/GbCloud/RiskObjSelect'
import RiskCategorySelect from '@weights/GbCloud/RiskCategorySelect'

import { filterParams } from '@utils/search.js'

export default {
    name: 'RFSearchPanel',
    components: {
        RiskObjSelect,
        RiskCategorySelect,
        AInput,
        SearchPanel,
        FormCol
    },
    data() {
        return {
            form: {
                query: '',
                masterDataId: '',
                categoryArr: [],
                masterDataClassificationId: ''
            },
            masterOption: null, // 风险对象选择的item,
            categoryOption: null // 风险分类选择的item
        }
    },
    watch: {
        masterOption() {
            // this.form.masterDataClassificationId = ''
        }
    },
    methods: {
        handleChange(val) {
            this.form.masterDataClassificationId = val
        },
        handleOption(option) {
            this.masterOption = option
        },
        handleCategoryOption(option) {
            this.categoryOption = option
        },
        /**
     * 响应搜索事件
     */
        handleSearch() {
            // eslint-disable-next-line no-unused-vars
            const { categoryArr, ...params } = this.form
            this.$emit('search', filterParams(params))
        },
        /**
     * 响应刷新事件
     */
        handleRefresh() {
            const { form } = this
            this.form.masterDataClassificationId = ''
            let { ...params } = form
            this.$emit('refresh', filterParams(params))
        }
    }
}
</script>

<style lang='scss'>

</style>
