<template>
    <search-panel :model="form"
                  layout="vertical"
                  class="enterprise-list-search-panel"
                  @search="handleSearch"
                  @refresh="handleRefresh">
        <form-col label="业务类型" prop="riskBusinessType">
            <risk-business-type
                v-model="form.riskBusinessType"
                slot="formElements" />
        </form-col>
        <form-col :label-span="6" label="风控模型名称" prop="query" class="enterprise-list-search-panel__risk-control">
            <a-input
                v-model.trim="form.query"
                slot="formElements"
                placeholder="请输入风控模型名称" />
        </form-col>
    </search-panel>
</template>

<script>
import {
    Input as AInput
} from 'ant-design-vue'
import SearchPanel from '@components/SearchPanel'
import FormCol from '@components/FormCol'
import RiskBusinessType from '@weights/RiskBusinessType'

import { filterParams } from '@utils/search.js'

export default {
    name: 'RCMSearchPanel',
    components: {
        RiskBusinessType,
        AInput,
        SearchPanel,
        FormCol
    },
    data() {
        return {
            form: {
                riskBusinessType: '',
                query: ''
            },
            masterOption: null, // 风险对象选择的item,
            categoryOption: null // 风险分类选择的item,
        }
    },
    watch: {},
    methods: {
    /**
     * 响应搜索事件
     */
        handleSearch() {
            const { form } = this
            let { ...params } = form
            this.$emit('search', filterParams(params))
        },
        /**
     * 响应刷新事件
     */
        handleRefresh() {
            const { form } = this
            let { ...params } = form
            this.$emit('refresh', filterParams(params))
        }
    }
}
</script>

<style lang='scss'>
.enterprise-list-search-panel {
  &__risk-control {
    .ant-form-item-label {
      min-width: 110px !important;
    }
  }
}
</style>
