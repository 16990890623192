<template>
    <a-form-model
        ref="ruleForm"
        class="add-edit-risk-class-form-panel"
        layout="horizontal"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 18 }"
        :model="form"
        :rules="rules">
        <a-form-model-item label="模型名称" prop="modelExamplesName">
            <a-input
                v-model.trim="form.modelExamplesName"
                :max-length="INPUT_MAX_LENGTH"
                placeholder="输入模型名称" />
        </a-form-model-item>
        <a-form-model-item label="业务类型" prop="riskBusinessType">
            <risk-business-type
                v-model="form.riskBusinessType" />
        </a-form-model-item>
        <a-form-model-item label="评分模型名称" prop="scoringModelId">
            <grade-model-select
                v-model="form.scoringModelId"
                mode="multiple" />
        </a-form-model-item>
        <a-form-model-item label="模型描述" prop="description">
            <a-input
                v-model.trim="form.description"
                type="textarea"
                :rows="3"
                :max-length="INPUT_AREA_MAX_LENGTH"
                placeholder="输入模型描述" />
        </a-form-model-item>

        <risk-class-row
            ref="classRow"
            :info="info" />
    </a-form-model>
</template>

<script>
import {
    FormModel as AFormModel, Input as AInput
} from 'ant-design-vue'
import RiskBusinessType from '@weights/RiskBusinessType'

import { ERROR_COMMIT_EDIT, ERROR_COMMIT_SUCCESS } from '@constant/error'
import { INPUT_MAX_LENGTH, INPUT_AREA_MAX_LENGTH } from '@constant/Const'

import addRiskClassService from '@service/ruleEngine/riskClass/addRiskClassService'
import editRiskClassService from '@service/ruleEngine/riskClass/editRiskClassService'
import InitForm from '@mixins/initForm'

export default {
    name: 'FormPanel',
    components: {
        AFormModel,
        AFormModelItem: AFormModel.Item,
        AInput,
        RiskBusinessType,
        RiskClassRow: () => import('../RiskClassRow'),
        GradeModelSelect: () => import('@weights/select/GradeModelSelect')
    },
    mixins: [InitForm],
    props: {
        mode: {
            type: String,
            default: ''
        },
        info: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            INPUT_MAX_LENGTH,
            INPUT_AREA_MAX_LENGTH,
            form: {
                id: '', // 模型id
                modelExamplesName: '', // 模型名称
                description: '', // 模型描述
                riskBusinessType: '', // 业务类型
                scoringModelId: undefined
            },
            rules: {
                modelExamplesName: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入'
                    }
                ],
                riskBusinessType: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请选择'
                    }
                ],
                scoringModelId: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请选择'
                    }
                ]
            }
        }
    },
    computed: {
        isAdd() {
            return this.mode === 'create'
        },
        serviceFn() {
            const { isAdd } = this
            return isAdd ? addRiskClassService : editRiskClassService
        },
        commitMsg() {
            const { isAdd } = this
            return isAdd ? ERROR_COMMIT_SUCCESS : ERROR_COMMIT_EDIT
        }
    },
    watch: {
        isAdd: {
            handler(val) {
                if (!val) {
                    const { info, form } = this
                    this.initForm(info, form)
                }
            },
            immediate: true
        }
    },
    methods: {
        handleSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    const riskScoreConfig = this.$refs.classRow.handleSubmit()
                    riskScoreConfig && this.submitService(riskScoreConfig)
                }
            })
        },
        async submitService(riskScoreConfig) {
            const { serviceFn, commitMsg, form } = this
            const parmas = {
                ...form,
                riskScoreConfig
            }
            try {
                this.$emit('loading', true)
                await serviceFn(parmas)
                this.$message.success(commitMsg)
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.$emit('loading', false)
            }
        }
    }
}
</script>

<style lang='scss'>
.add-edit-risk-class-form-panel {
  .ant-form-item-control {
    line-height: 0;
  }
}
</style>
