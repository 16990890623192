<!-- 全国地区业务 -->
<template>
    <a-form-model
        ref="ruleForm"
        :rules="rules"
        :model="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        class="all-can-used-form">
        <a-form-model-item label="配置默认内容" prop="formatContent">
            <a-textarea
                v-model="form.formatContent"
                ref="textarea"
                :max-length="4000"
                placeholder="配置默认内容"
                :rows="6"
                :disabled="isLook"
                @focus="isFocus=true" />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{offset:3,span:10}">
            <div class="risk-assessment-content__insert-field-container">
                <div class="risk-assessment-content__insert-field-container__button">
                    <a-button
                        v-show="!isLook"
                        v-auth="RULE_ENGINE_DELETE_RISK_ASSESSMENT_CONTENT_ALL_CAN_USED_INSERT_FIELD_BUTTON"
                        type="primary"
                        :disabled="!isFocus"
                        @click="handleInsert"
                    >
                        插入字段
                    </a-button>
                </div>
                <div>插入字段</div>
            </div>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{offset:3,span:18}">
            <div style="overflow-x: scroll;">
                <table>
                    <colgroup>
                        <col style="width: 100px; min-width: 100px;">
                        <col style="width: 200px; min-width: 200px;">
                        <col style="width: 200px; min-width: 200px;">
                        <col style="width: 300px; min-width: 300px;">
                        <col style="width: 150px; min-width: 150px;">
                        <col style="width: 200px; min-width: 200px;">
                    </colgroup>
                    <thead class="all-can-used-form__thead">
                        <tr>
                            <th
                                v-for="item in tabKesData"
                                :key="item.name"
                                class="ant-table-align-center">
                                <div :style="{textAlign:'left',}">{{ item.name }}</div>
                            </th>
                        </tr>
                    </thead>
                </table>
                <insert-assessment-field
                    v-for="(item,index) in fieldData"
                    :key="item._id"
                    :ref="`fieldRef${index}`"
                    style="min-width: 1100px"
                    :is-look="isLook"
                    :field-info="item"
                    :index="index"
                    :field-data="fieldData"
                    @delete="handleDelete"
                    @factor-select="handleFactorSelect"
                />
            </div>
        </a-form-model-item>
    </a-form-model>
</template>

<script>
import { FormModel as AFormModel, Input as AInput, Button as AButton } from 'ant-design-vue'
import { RULE_ENGINE_DELETE_RISK_ASSESSMENT_CONTENT_ALL_CAN_USED_INSERT_FIELD_BUTTON } from '@constant/authEnum/ruleEngine'
import InsertAssessmentField
from '@views/RuleEngine/RiskAssessmentModel/AddOrEditRiskAssessmentContent/InsertAssessmentField'
import { insertText } from '@weights/SetComputedFormulaModal/FormPanel/core'
import { INPUT_AREA_MAX_LENGTH } from '@constant/Const'
export default {
    name: 'AllCanUsed',
    components: {
        AFormModel,
        AFormModelItem: AFormModel.Item,
        ATextarea: AInput.TextArea,
        InsertAssessmentField,
        AButton
    },
    props: {
        contentInfo: {
            type: Object,
            default: Object
        },
        isLook: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            INPUT_AREA_MAX_LENGTH,
            RULE_ENGINE_DELETE_RISK_ASSESSMENT_CONTENT_ALL_CAN_USED_INSERT_FIELD_BUTTON,
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 10
            },
            fieldData: [],
            tabKesData: [
                {
                    name: '序号',
                    width: 100,
                    align: 'center'
                },
                {
                    name: '风险对象',
                    width: 200,
                    align: 'center'
                },
                {
                    name: '风险分类',
                    width: 200,
                    align: 'center'
                },
                {
                    name: '显示风险因子',
                    width: 200,
                    align: 'center'
                },
                {
                    name: '是否作为计算公式',
                    width: 150,
                    align: 'center'
                },
                {
                    name: '操作',
                    width: 200,
                    align: 'center'
                }
            ],
            isFocus: false,
            form: {
                formatContent: ''
            },
            rules: {
                formatContent: [
                    {
                        required: true,
                        message: '请输入配置默认内容',
                        trigger: 'blur'
                    }
                ]
            }
        }
    },
    watch: {
        contentInfo: {
            handler(val) {
                const { formatContent, riskAssessmentContentDetails } = val
                this.form.formatContent = formatContent
                this.fieldData = riskAssessmentContentDetails
                this.fieldData && this.fieldData.forEach((item, index) => {
                    item._id = index
                })
            }
        }
    },
    methods: {
        handleFormReset() {
            this.$refs.ruleForm.resetFields()
            const { fieldData } = this
            this.fieldData = []
            fieldData.map((item, index) => {
                this.$refs[`fieldRef${index}`][0].handleFormReset()
            })
        },
        handleFormValidate() {
            let res = null
            const { fieldData, form } = this
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    // 如果插入了字段
                    if (fieldData.length) {
                        // 收集每个字段里的form
                        let riskAssessmentContentDetails = fieldData.map((item, index) => this.$refs[`fieldRef${index}`][0].handleFormValidate())
                        if (riskAssessmentContentDetails.includes(null)) return null
                        res = {
                            ...form,
                            riskAssessmentContentDetails
                        }
                    } else {
                        res = form
                    }
                }
            })
            return res
        },
        handleInsert() {
            const { fieldData } = this
            const len = fieldData.length
            this.fieldData.push({
                _id: len,
                isFormula: false
            })
            this.isFocus = false
        },
        handleDelete(index, val) {
            this.fieldData.splice(index, 1)
            if (val) {
                this.form.formatContent = this.form.formatContent.replaceAll(val, '')
            }
        },
        /**
         * 因子选择完插入字段
         * */
        handleFactorSelect(val) {
            const el = this.$refs.textarea.$el
            this.form.formatContent = insertText(el, val)
            el.focus()
        }
    }
}
</script>

<style lang='scss'>
@import '@assets/styles/varibles.scss';

.all-can-used-form {
  &__thead {
    background-color: $custom-table-title-background-color;

    tr {
      line-height: $custom-table-thead-height
    }
  }

  th,
 td {
    text-align: left;
    word-break: break-all;
    padding: 0 10px;
    //min-width: 100px;
  }
}
</style>
