<template>
    <custom-modal
        :title="title"
        :visible="visible"
        :mask-closable="false"
        :width="650"
        @close="handleCancel">
        <template slot="customFooter">
            <a-button key="reset" @click="handleCancel">
                取消
            </a-button>
            <a-button v-show="!isLook"
                      key="submit"
                      type="primary"
                      :loading="loading"
                      @click="handleOk">
                提交
            </a-button>
        </template>
        <form-panel
            ref="formPanel"
            :area-list="areaList"
            :info="info"
            :mode="mode"
            @loading="handleLoading"
            @edit="handleEdit"
            @success="handleSuccess" />
    </custom-modal>
</template>

<script>
import { Button as AButton } from 'ant-design-vue'
import CustomModal from '@components/CustomModal'
import FormPanel from './FormPanel'

export default {
    name: 'AreaDataSettingModal',
    components: {
        AButton,
        CustomModal,
        FormPanel
    },
    props: {
        mode: {
            type: String,
            default: ''
        },
        areaList: {
            type: Array,
            default() {
                return []
            }
        },
        info: {
            type: Object,
            default() {
                return {}
            }
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false
        }
    },
    computed: {
        isLook() {
            const { mode } = this
            return mode === 'look'
        },
        title() {
            const { isLook } = this
            if (isLook) {
                return '详情'
            } else {
                return '配置地区数据'
            }
        }
    },
    methods: {
        handleLoading(value) {
            this.loading = value
        },
        handleCancel() {
            this.$emit('cancel')
        },
        handleOk() {
            this.$refs.formPanel.handleSubmit()
        },
        handleSuccess(res) {
            this.loading = false
            this.$emit('success', res)
        },
        handleEdit(res) {
            this.$emit('edit', res)
        }
    }
}
</script>

<style lang='scss'>

</style>
