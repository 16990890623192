<template>
    <a-row>
        <a-col span="16" offset="3">
            <a-input-search
                placeholder="请输入地区进行搜索"
                style="width: 270px; margin-bottom: 20px"
                @search="onSearch" />
            <a-table
                :columns="columns"
                :data-source="dataList"
                :pagination="pagination"
                :loading="loading"
                row-key="areaCode"
                @change="handleTableChange">
                <template slot="index" slot-scope="text,action,index">
                    {{ index + 1 }}
                </template>
                <template slot="content" slot-scope="text,action">
                    <a-space>
                        <a-button
                            v-auth="RULE_ENGINE_DELETE_RISK_ASSESSMENT_CONTENT_CITY_CAN_USED_INFO_BUTTON"
                            type="link"
                            @click="handleLook(action)"
                        >
                            详情
                        </a-button>
                    </a-space>
                </template>
                <template slot="action" slot-scope="text,action">
                    <a-space>
                        <a-button
                            v-show="showEdit"
                            v-auth="RULE_ENGINE_DELETE_RISK_ASSESSMENT_CONTENT_CITY_CAN_USED_EDIT_BUTTON"
                            type="link"
                            @click="handleEdit(action)">
                            编辑
                        </a-button>
                        <a-button
                            v-show="showDeleteButton"
                            v-auth="RULE_ENGINE_DELETE_RISK_ASSESSMENT_CONTENT_CITY_CAN_USED_DELETE_BUTTON"
                            type="link"
                            @click="handleDelete(action)"
                        >
                            删除
                        </a-button>
                    </a-space>
                </template>
            </a-table>
        </a-col>
        <area-data-setting-modal
            :area-list="allTargetCityData"
            :visible="visible"
            :info="info"
            :mode="mode"
            @cancel="handleCancel"
            @success="handleSuccess"
            @edit="editSuccess" />
    </a-row>
</template>

<script>
import {
    Input as AInput, Table as ATable, Space as ASpace, Row as ARow, Button as AButton,
    Col as ACol
} from 'ant-design-vue'
import AreaDataSettingModal from '../AreaDataSettingModal'
import { DEFAULT_PAGES_SIZE } from '@config'
import {
    RULE_ENGINE_DELETE_RISK_ASSESSMENT_CONTENT_CITY_CAN_USED_DELETE_BUTTON,
    RULE_ENGINE_DELETE_RISK_ASSESSMENT_CONTENT_CITY_CAN_USED_EDIT_BUTTON,
    RULE_ENGINE_DELETE_RISK_ASSESSMENT_CONTENT_CITY_CAN_USED_INFO_BUTTON
} from '@constant/authEnum/ruleEngine'
import tableShowTotal from '@mixins/tableShowTotal'
import getArea from '@mixins/getArea'

const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        align: 'center',
        key: 'index',
        scopedSlots: {
            customRender: 'index'
        },
        width: 100
    },
    {
        title: '省',
        dataIndex: 'provinceName',
        align: 'center',
        key: 'provinceName',
        scopedSlots: {
            customRender: 'provinceName'
        },
        width: 150
    },
    {
        title: '市',
        dataIndex: 'cityName',
        align: 'center',
        key: 'cityName',
        scopedSlots: {
            customRender: 'cityName'
        },
        width: 150
    },
    {
        title: '内容',
        dataIndex: 'content',
        align: 'center',
        key: 'content',
        scopedSlots: {
            customRender: 'content'
        },
        width: 150
    },
    {
        title: '操作',
        dataIndex: 'action',
        align: 'center',
        key: 'action',
        scopedSlots: {
            customRender: 'action'
        },
        width: 150
    }
]
export default {
    name: 'CityCanUsed',
    components: {
        AInputSearch: AInput.Search,
        ATable,
        ARow,
        ASpace,
        ACol,
        AButton,
        AreaDataSettingModal
    },
    mixins: [tableShowTotal, getArea],
    props: {
        isLook: {
            type: Boolean,
            default: false
        },
        contentInfo: {
            type: Object,
            default: Object
        }
    },
    data() {
        return {
            RULE_ENGINE_DELETE_RISK_ASSESSMENT_CONTENT_CITY_CAN_USED_DELETE_BUTTON,
            RULE_ENGINE_DELETE_RISK_ASSESSMENT_CONTENT_CITY_CAN_USED_INFO_BUTTON,
            RULE_ENGINE_DELETE_RISK_ASSESSMENT_CONTENT_CITY_CAN_USED_EDIT_BUTTON,
            columns,
            // dataList: [],
            allCityData: [], // 所有的数据
            limit: DEFAULT_PAGES_SIZE,
            // total: 0,
            current: 1,
            loading: false,
            visible: false,
            mode: '',
            info: {},
            allTargetCityData: [], // 总数据备份
            hasSearch: false
        }
    },
    computed: {
        pagination() {
            const { total, limit, current, tableShowTotal } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        },
        showEdit() {
            const { isLook } = this
            return !isLook
        },
        showDeleteButton() {
            return this.dataList.length > 1 && this.showEdit
        },
        total() {
            return this.allCityData.length
        },
        dataList() {
            const { current, limit, allCityData } = this
            const startIndex = (current - 1) * limit
            return allCityData.slice(startIndex, startIndex + limit )
        }
    },
    watch: {
        contentInfo: {
            async handler(val) {
                await this.getAreaList()
                const regionalBusinessExpansions = val.regionalBusinessExpansions || []
                regionalBusinessExpansions.forEach(item => {
                    const { provinceName, cityName } = this.getAreaName(this.mapArea, item.areaCode)
                    item.provinceName = provinceName
                    item.cityName = cityName
                })
                this.allCityData = regionalBusinessExpansions
                this.allTargetCityData = JSON.parse(JSON.stringify(this.allCityData))
            }
        }
    },
    created() {
        this.handleTableChange({
            current: 1,
            pageSize: 10
        })
    },
    methods: {
        handleFormReset() {
            // 添加成功后清空已有数据
            this.allCityData = []
            this.allTargetCityData = []
        },
        handleLook(action) {
            this.mode = 'look'
            this.info = action
            this.visible = true
        },
        handleEdit(action) {
            this.mode = 'edit'
            this.info = action
            this.visible = true
        },
        handleAdd() {
            this.info = {}
            this.mode = 'create'
            this.visible = true
        },
        handleDelete(action) {
            this.allCityData = this.allCityData.filter(item => item.areaCode !== action.areaCode)
            this.allTargetCityData = JSON.parse(JSON.stringify(this.allCityData))
            // this.total = this.allCityData.length
        },
        onSearch(val) {
            let { hasSearch } = this
            if (!hasSearch) {
                this.allTargetCityData = JSON.parse(JSON.stringify(this.allCityData))
            }
            this.hasSearch = true
            this.allCityData = this.allTargetCityData.filter(item => item.cityName.includes(val) || item.provinceName.includes(val))
        },
        handleCancel() {
            this.visible = false
        },
        // 添加成功
        handleSuccess(res) {
            this.allCityData.unshift(res)
            this.allTargetCityData = JSON.parse(JSON.stringify(this.allCityData))
            // this.total = this.allCityData.length
            this.visible = false
        },
        // 修改成功
        editSuccess(res) {
            let findItem = this.allCityData.find(item => item.areaCode === res.areaCode)
            findItem.content = res.content
            this.allTargetCityData = JSON.parse(JSON.stringify(this.allCityData))
            this.visible = false
        },
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
        },
        handleFormValidate() {
            const { allTargetCityData } = this
            if (!allTargetCityData.length) {
                this.$message.warning('请选择地区')
                return null
            }
            return {
                regionalBusinessExpansions: allTargetCityData
            }
        },
        getList() {

        }
    }
}
</script>

<style lang='scss'>

</style>
