<template>
    <page-layout class="add-edit-grade-factor-page" :show-loading="true" :loading="loading">
        <page-panel>
            <a-form-model
                ref="ruleForm"
                :model="form"
                :rules="rules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol">
                <a-form-model-item label="规则集名称" prop="modelExamplesName">
                    <a-input
                        v-model.trim="form.modelExamplesName"
                        placeholder="请输入规则集名称"
                        :max-length="INPUT_MAX_LENGTH" />
                </a-form-model-item>
                <a-form-model-item label="规则集描述" prop="description">
                    <a-input
                        v-model.trim="form.description"
                        type="textarea"
                        :rows="4"
                        :max-length="INPUT_MAX_LENGTH_500"
                    />
                </a-form-model-item>
                <a-form-model-item label="业务类型" prop="riskBusinessType">
                    <risk-business-type
                        v-model="form.riskBusinessType" />
                </a-form-model-item>
                <a-form-model-item label="产品分类" prop="riskCategoryId">
                    <product-category-cascader v-model="form.riskCategoryId" />
                </a-form-model-item>
                <a-form-model-item label="规则集分类" prop="ruleSetType">
                    <rule-set-type
                        v-model="form.ruleSetType"
                        @change="handleRuleSetTypeSelect"
                    />
                </a-form-model-item>
                <a-form-model-item label="保司选择" prop="insuranceCompanyIds">
                    <sys-list
                        v-model="form.insuranceCompanyIds"
                        ref="sysList"
                        :mode="sysMode"
                        :code="sysCode"
                    />
                </a-form-model-item>
                <a-form-model-item label="规则选择" prop="basicRuleIds">
                    <div>
                        <select-option-label-prop
                            v-model="form.basicRuleIds"
                            ref="option"
                            :select-options-data="basRuleList"
                            option-label="name"
                            option-value="id"
                            placeholder="请选择规则"
                            :filter-option="false" />
                    </div>
                </a-form-model-item>

                <a-form-model-item :wrapper-col="{ span: 8, offset: 10 }">
                    <a-button type="default" @click="handleCancel">取消</a-button>
                    <a-button type="primary" :loading="submitLoading" style="margin-left: 20px;" @click="handleSubmit">
                        提交
                    </a-button>
                </a-form-model-item>
            </a-form-model>
        </page-panel>
    </page-layout>
</template>

<script>
import {
    FormModel as AFormModel,
    Button as AButton, Input as AInput
} from 'ant-design-vue'
import PageLayout from '@layout/PageLayout'

import {
    PRODUCT_CATEGORY_TREE_ENABLE_OPEN,
    RULE_SET_TYPE_ENUM_LIST, RULE_SET_TYPE_A1_VALUE
} from '@constant/enum'
import { DEFAULT_PAGES_SIZE } from '@config'
import { ERROR_COMMIT_SUCCESS, ERROR_COMMIT_EDIT } from '@constant/error'
import { INPUT_MAX_LENGTH, INPUT_MAX_LENGTH_500 } from '@constant/Const'

import addService from '@service/ruleEngine/ruleSet/addRuleService'
import editService from '@service/ruleEngine/ruleSet/editRuleService'
import getInfoService from '@service/ruleEngine/ruleSet/getRuleInfoService'
import getAllRuleListService from '@service/ruleEngine/baseRule/getAllRuleListService'

import InitForm from '@mixins/initForm'

export default {
    name: 'AddRiskFactor',
    components: {
        AButton,
        PageLayout,
        AInput,
        AFormModel,
        AFormModelItem: AFormModel.Item,
        PagePanel: () => import('@components/PagePanel'),
        RiskBusinessType: () => import('@weights/RiskBusinessType'),
        ProductCategoryCascader: () => import('@weights/ProductCategoryCascader'),
        SelectOptionLabelProp: () => import('@weights/SelectOptionLabelProp'),
        SysList: () => import('@weights/select/SysList'),
        RuleSetType: () => import('@weights/select/RuleSetType')
    },
    mixins: [InitForm],
    data() {
        return {
            INPUT_MAX_LENGTH,
            INPUT_MAX_LENGTH_500,
            RULE_SET_TYPE_ENUM_LIST,
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 10
            },
            form: {
                modelExamplesName: undefined,
                description: undefined,
                riskBusinessType: undefined,
                riskCategoryId: undefined,
                ruleSetType: undefined,
                insuranceCompanyIds: undefined,
                basicRuleIds: undefined
            },
            loading: false,
            submitLoading: false,
            dataList: [],
            limit: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            rules: {
                modelExamplesName: [
                    {
                        required: true,
                        message: '请输入规则集名称',
                        trigger: 'blur'
                    }
                ],
                masterDataId: [
                    {
                        required: true,
                        message: '请选择风险对象',
                        trigger: 'blur'
                    }
                ],
                riskBusinessType: [
                    {
                        required: true,
                        message: '请选择业务类型',
                        trigger: 'blur'
                    }
                ],
                riskCategoryId: [
                    {
                        required: true,
                        message: '请选择产品分类',
                        trigger: 'blur'
                    }
                ],
                ruleSetType: [
                    {
                        required: true,
                        message: '请选择规则集分类',
                        trigger: 'blur'
                    }
                ],
                insuranceCompanyIds: [
                    {
                        required: true,
                        message: '请选择保司',
                        trigger: 'blur'
                    }
                ],
                basicRuleIds: [
                    {
                        required: true,
                        message: '请选择规则',
                        trigger: 'blur'
                    }
                ]
            },
            ruleInfo: {}, // 详情页
            basRuleList: [], // 基础规则列表
            sysDictList: [], // 保司列表
            sysCode: 'dataCode', // 保司key
            sysMode: '' // 保司选择 单选/多选
        }
    },
    computed: {
        id() {
            return this.$route.query.id || ''
        },
        // 修改时部分表单不可修改
        isDisabled() {
            const { edit, id } = this.$route.query
            return id && edit && !!+edit
        },
        serviceFn() {
            const { id } = this
            return id ? editService : addService
        },
        commitMsg() {
            const { id } = this
            return id ? ERROR_COMMIT_EDIT : ERROR_COMMIT_SUCCESS
        },
        isA1() {
            return this.form.ruleSetType === RULE_SET_TYPE_A1_VALUE
        },
        params() {
            const { sysMap } = this.$refs.sysList
            const { insuranceCompanyIds, ...params } = this.form

            const ids = Array.isArray(insuranceCompanyIds) ? insuranceCompanyIds : [insuranceCompanyIds]

            const insuranceCompanyShortNames = ids.map(item => sysMap.get(item)?.dataValue).join('|')

            return {
                insuranceCompanyIds: ids.join('|'),
                insuranceCompanyShortNames,
                ...params
            }
        }

    },
    created() {
        this.getBaseRuleList()
        const { id } = this
        if (id) {
            this.form.id = id
            this.getInfo()
        }

    },
    methods: {
        // 规则集分类 切换 清空保司值
        handleRuleSetTypeSelect() {
            if (this.isA1) {
                this.form.insuranceCompanyIds = []
                this.sysMode = 'multiple'
            } else {
                this.form.insuranceCompanyIds = ''
                this.sysMode = ''
            }
        },
        handleLoading(val) {
            this.loading = val
        },
        handleCancel() {
            this.$router.back()
        },
        handleSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.sendCommitService()
                }
            })
        },
        async sendCommitService() {
            try {
                this.submitLoading = true
                const { params, commitMsg, serviceFn } = this
                await serviceFn(params)
                this.$message.success(commitMsg)
                this.$router.back()
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.submitLoading = false
            }
        },
        // 详情页数据
        async getInfo() {
            try {
                this.loading = true
                const { id } = this

                this.ruleInfo = await getInfoService(id)

                this.initForm(this.ruleInfo, this.form)
                const { isA1, ruleInfo } = this
                const { insuranceCompanyIds } = ruleInfo
                if (isA1) { // A1 保司为数组
                    this.form.insuranceCompanyIds = insuranceCompanyIds.split('|')
                }
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        // 基础规则列表
        async getBaseRuleList() {
            try {
                this.basRuleList = await getAllRuleListService({
                    state: PRODUCT_CATEGORY_TREE_ENABLE_OPEN
                })
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        }
    }
}
</script>

<style lang='scss'>
.add-edit-grade-factor-page {
    .ant-form-item-label {
        min-width: 130px;
    }

    &__weight-input {
        width: 150px !important;
        margin-right: 8px
    }

    &__set-formula-text {
        float: right;
        color: #2998ff;
        font-size: 14px;
        cursor: pointer;
    }
}
</style>
