<!-- 数据核验模型-数据核验模型详情-基本信息 -->
<template>
    <div>
        <audit-state
            :info="info" />
        <container-panel class="basic-info-tab-panel">
            <descriptions-with-head title="基本信息">
                <a-button
                    v-if="canAction"
                    slot="button"
                    v-auth="RULE_ENGINE_DATA_CHECK_MODEL_EDIT_BASIC_INFO_BUTTON"
                    type="primary"
                    @click="handleEdit">
                    编辑
                </a-button>
                <a-descriptions bordered :column="1">
                    <a-descriptions-item label="风险等级模型名称">
                        {{ empty(info.modelExamplesName) }}
                    </a-descriptions-item>
                    <a-descriptions-item label="模型描述">
                        {{
                            empty(info.description)
                        }}
                    </a-descriptions-item>
                    <a-descriptions-item label="业务类型">
                        {{
                            empty(BUSINESS_TYPE_MAP[info.riskBusinessType])
                        }}
                    </a-descriptions-item>
                    <a-descriptions-item label="评分模型名称">
                        {{
                            empty(modelName)
                        }}
                    </a-descriptions-item>
                </a-descriptions>
            </descriptions-with-head>

            <descriptions-with-head title="风险等级">
                <a-descriptions
                    v-for="item in info.riskScoreConfig"
                    :key="item.id"
                    bordered
                    :column="1">
                    <a-descriptions-item :label="item.name">
                        <img :src="item.levelImg" alt="" class="level">
                        <span class="basic-info-tab-panel__risk-score">分值区间：</span>{{ getScoreSection(item) }}
                        <span class="basic-info-tab-panel__risk-score">等级描述：</span>{{ item.levelDesc }}
                    </a-descriptions-item>
                </a-descriptions>
            </descriptions-with-head>
        </container-panel>
        <add-or-edit-risk-class-modal
            mode="edit"
            :visible="visible"
            :info="info"
            @success="handleSuccess"
            @cancel="handleCancel" />
    </div>
</template>

<script>
import ContainerPanel from '@components/ContainerPanel'
import { Descriptions as ADescriptions, Button as AButton } from 'ant-design-vue'
import DescriptionsWithHead from '@components/DescriptionsWithHead'
import AddOrEditRiskClassModal from '@views/RuleEngine/RiskClass/AddOrEditRiskClassModal'
import AuditState from '@weights/AuditState'

import { RULE_ENGINE_DATA_CHECK_MODEL_EDIT_BASIC_INFO_BUTTON } from '@constant/authEnum/ruleEngine'
import { BUSINESS_TYPE_MAP, GREATER_AND_LESS_THAN_TYPE_ENUM_LIST } from '@constant/enum'

import enumOperate from '@mixins/enumOperate'
import empty from '@mixins/empty'
import showModelButton from '@utils/showModelButton'

export default {
    name: 'BasicInfoTabPanel',
    components: {
        ContainerPanel,
        ADescriptions,
        ADescriptionsItem: ADescriptions.Item,
        DescriptionsWithHead,
        AButton,
        AddOrEditRiskClassModal,
        AuditState
    },
    mixins: [empty, enumOperate],
    props: {
        info: {
            type: Object,
            default() {
                return {}
            }
        },
        isLook: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            visible: false,
            BUSINESS_TYPE_MAP,
            RULE_ENGINE_DATA_CHECK_MODEL_EDIT_BASIC_INFO_BUTTON
        }
    },
    computed: {
        modelName() {
            return this.info.scoringModelName?.join('、')
        },
        // 可操作
        canAction() {
            const { isLook, info } = this
            const canAction = showModelButton.canEdit(info.auditState)
            return !isLook && canAction
        }
    },
    methods: {
        handleSuccess() {
            this.visible = false
            this.$emit('success')
        },
        handleCancel() {
            this.visible = false
        },
        handleEdit() {
            this.visible = true
        },
        getScoreSection(item) {
            const { min, max, minValueComparator, maxValueComparator } = item
            const leftSymbol = this.getApiEnumLabelFromList(GREATER_AND_LESS_THAN_TYPE_ENUM_LIST, minValueComparator, 'text')
            const rightSymbol = this.getApiEnumLabelFromList(GREATER_AND_LESS_THAN_TYPE_ENUM_LIST, maxValueComparator, 'text')
            return `${leftSymbol}${min},${max}${rightSymbol}`
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.basic-info-tab-panel {
    .ant-descriptions-item-label {
        width: $descriptions-item-label-width;
        padding: $descriptions-item-label-padding;
    }

    &__risk-score {
        margin-left: 20px;
        font-size: 15px;
        font-weight: bold;
    }
    .level {
        width: 80px;
        height: auto;
    }
}
</style>
