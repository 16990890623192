<template>
    <a-form-model
        ref="ruleForm"
        class="add-edit-category-form-panel"
        layout="horizontal"
        :label-col="{ span: 5}"
        :wrapper-col="{ span: 16}"
        :model="form"
        :rules="rules">
        <a-form-model-item label="业务类型" prop="modelType">
            <risk-business-type
                v-if="isAdd"
                v-model="form.modelType" />
            <div v-else>
                {{ empty(BUSINESS_TYPE_MAP[form.modelType]) }}
            </div>
        </a-form-model-item>
        <a-form-model-item v-if="!isAdd" label="风控模型编号">
            {{ info.id }}
        </a-form-model-item>
        <a-form-model-item label="风控模型名称" prop="modelName">
            <a-input
                v-model.trim="form.modelName"
                :max-length="INPUT_MAX_LENGTH"
                placeholder="请输入风控模型名称"
                type="text" />
        </a-form-model-item>
        <a-form-model-item label="风控模型描述" prop="description">
            <a-input
                v-model.trim="form.description"
                :max-length="INPUT_AREA_MAX_LENGTH"
                placeholder="请输入风控模型描述"
                type="text" />
        </a-form-model-item>
    </a-form-model>
</template>

<script>
import {
    FormModel as AFormModel, Input as AInput
} from 'ant-design-vue'
import RiskBusinessType from '@weights/RiskBusinessType'

import InitForm from '@mixins/initForm'
import enumOperate from '@mixins/enumOperate'

import { ERROR_COMMIT_EDIT, ERROR_COMMIT_SUCCESS } from '@constant/error'
import { BUSINESS_TYPE_MAP } from '@constant/enum'
import { INPUT_MAX_LENGTH, INPUT_AREA_MAX_LENGTH } from '@constant/Const'

import addRiskControlModelService from '@service/ruleEngine/riskControlModel/addRiskControlModelService'
import editRiskControlModelService from '@service/ruleEngine/riskControlModel/editRiskControlModelService'

export default {
    name: 'FormPanel',
    components: {
        RiskBusinessType,
        AInput,
        AFormModel,
        AFormModelItem: AFormModel.Item
    },
    mixins: [InitForm, enumOperate],
    props: {
        mode: {
            type: String,
            default: ''
        },
        info: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            INPUT_MAX_LENGTH,
            INPUT_AREA_MAX_LENGTH,
            BUSINESS_TYPE_MAP,
            form: {
                modelType: '',
                modelName: '',
                description: '',
                id: ''
            },
            rules: {
                modelType: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请选择业务类型'
                    }
                ],
                modelName: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入风控模型名称'
                    }
                ]
            },
            factorList: [],
            masterOption: null, // 风险对象选择的item,
            categoryOption: null// 风险分类选择的item,
        }
    },
    computed: {
        isAdd() {
            return this.mode === 'create'
        },
        serviceFn() {
            const { isAdd } = this
            return isAdd ? addRiskControlModelService : editRiskControlModelService
        },
        commitMsg() {
            const { isAdd } = this
            return isAdd ? ERROR_COMMIT_SUCCESS : ERROR_COMMIT_EDIT
        }
    },
    watch: {
        mode: {
            handler() {
                const { isAdd, info, form } = this
                if (isAdd) return
                this.initForm(info, form)
                this.rules.modelType = [
                    {
                        required: false,
                        trigger: 'blur',
                        message: '请选择业务类型'
                    }
                ]
            },
            immediate: true
        }
    },
    methods: {
        handleOption(option) {
            this.masterOption = option
        },
        handleCategoryOption(option) {
            this.categoryOption = option
        },
        handleSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.submitService()
                }
            })
        },
        async submitService() {
            const { serviceFn, commitMsg, form } = this
            try {
                this.$emit('loading', true)
                await serviceFn(form)
                this.$message.success(commitMsg)
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.$emit('loading', false)
            }

        }


    }
}
</script>

<style lang='scss'>

</style>
