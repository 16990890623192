<template>
    <a-form-model
        ref="ruleForm"
        class="add-edit-category-form-panel"
        layout="horizontal"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 18 }"
        :model="form"
        :rules="rules">
        <a-form-model-item label="风险对象" prop="riskObj">
            <risk-obj-select
                v-model="form.riskObj"
                @option="handleOption" />
        </a-form-model-item>
        <a-form-model-item label="风险分类" prop="riskCategory">
            <risk-category-select
                v-model="form.riskCategory"
                :option="masterOption"
                @change="handleCategoryChange" />
        </a-form-model-item>
        <a-form-model-item label="风险因子" prop="riskFactor">
            <select-option-label-prop
                v-model="form.riskFactor"
                ref="option"
                :select-options-data="factorList"
                :option-label="optionLabel"
                :option-value="optionValue"
                placeholder="请选择风险因子"
                :filter-option="false" />
        </a-form-model-item>
    </a-form-model>
</template>

<script>
import {
    FormModel as AFormModel
} from 'ant-design-vue'
import RiskObjSelect from '@weights/GbCloud/RiskObjSelect'
import RiskCategorySelect from '@weights/GbCloud/RiskCategorySelect'
import SelectOptionLabelProp from '@weights/SelectOptionLabelProp'

import { ERROR_COMMIT_SUCCESS } from '@constant/error'


import getCloudRiskFactorService from '@service/gbCloud/getCloudRiskFactorService'
import addRiskFactorService from '@service/ruleEngine/riskFactor/addRistFactorService'

export default {
    name: 'FormPanel',
    components: {
        AFormModel,
        AFormModelItem: AFormModel.Item,
        RiskObjSelect,
        RiskCategorySelect,
        SelectOptionLabelProp
    },
    props: {
        record: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            form: {
                riskObj: '', // 风险对象
                riskCategory: [], // 风险分类 级联
                riskFactor: []// 风险因子
            },
            rules: {
                riskObj: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请选择风险对象'
                    }
                ],
                riskCategory: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请选择风险分类'
                    }
                ],
                riskFactor: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请选择风险因子'
                    }
                ]
            },
            factorList: [],
            masterOption: null, // 风险对象选择的item,
            categoryId: null, // 风险分类选择的id
            optionLabel: 'factorName', // 因子select 动态label
            optionValue: 'factorCode'// 因子select 动态value
        }
    },
    computed: {},
    watch: {
        masterOption() {
            this.form.riskCategory = []
            this.form.riskFactor = []
        },
        categoryId(val) {
            this.form.riskFactor = []
            this.factorList = []
            if (!val) return
            this.getFactorList()
        }
    },
    methods: {
        handleOption(option) {
            this.masterOption = option
        },
        handleCategoryChange(categoryId) {
            this.categoryId = categoryId
        },
        handleSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.submitService()
                }
            })
        },
        async getFactorList() {
            try {
                const { masterDataId, source } = this.masterOption
                const { categoryId } = this
                this.factorList = await getCloudRiskFactorService({
                    masterDataId,
                    source,
                    categoryId
                })
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        },
        async submitService() {
            const { masterOption, categoryId } = this
            const factorCodes = this.form.riskFactor
            let obj = {} // 接口参数
            const { masterDataId, source } = masterOption
            obj = {
                masterDataId,
                source,
                categoryId
            }
            obj.factorCodes = factorCodes.join(',') // 因子ids
            try {
                this.$emit('loading', true)
                await addRiskFactorService(obj)
                this.$message.success(ERROR_COMMIT_SUCCESS)
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.$emit('loading', false)
            }

        }


    }
}
</script>

<style lang='scss'>

</style>
