<template>
    <list-page-layout>
        <search-panel slot="header" @refresh="handleSearch" @search="handleSearch" />
        <container-panel>
            <template slot="title">
                风险等级列表
            </template>
            <a-button slot="button"
                      v-auth="RULE_ENGINE_ADD_RISK_CLASS_BUTTON"
                      type="primary"
                      @click="handleAdd">
                添加风险等级
            </a-button>
            <template slot="content" slot-scope="">
                <a-table :columns="columns"
                         :data-source="dataList"
                         :pagination="pagination"
                         :loading="loading"
                         row-key="id"
                         :scroll="scrollConfig"
                         @change="handleTableChange">
                    <template slot="state" slot-scope="text,record">
                        <a-switch
                            :disabled="record.auditState!==DATA_CHECK_MODEL_AUDIT_STATE_PASS"
                            :checked="Boolean(record.state)"
                            @change="value => handleSwitch(value, record)" />
                    </template>
                    <template slot="action" slot-scope="text,action">
                        <a-button
                            v-auth="RULE_ENGINE_LOOK_RISK_CLASS_INFO_BUTTON"
                            type="link"
                            @click="handleLook(action)">
                            查看
                        </a-button>
                        <!--            <a-button
                type="link"
                v-auth="RULE_ENGINE_EDIT_RISK_CLASS_BUTTON"
                @click="handleEdit(action)">
              查看
            </a-button>
            <a-button
                type="link"
                v-auth="RULE_ENGINE_DELETE_RISK_CLASS_BUTTON"
                @click="handleDelete(action)">
              删除
            </a-button> -->
                    </template>
                </a-table>
            </template>
        </container-panel>

        <add-or-edit-risk-class-modal
            :mode="mode"
            :visible="visible"
            :info="info"
            @cancel="handleCancel"
            @success="handleSuccess" />
    </list-page-layout>
</template>

<script>
import { Button as AButton, Table as ATable, Switch as ASwitch } from 'ant-design-vue'
import {
    RULE_ENGINE_ADD_RISK_CLASS_BUTTON, RULE_ENGINE_DELETE_RISK_CLASS_BUTTON,
    RULE_ENGINE_EDIT_RISK_CLASS_BUTTON, RULE_ENGINE_LOOK_RISK_CLASS_INFO_BUTTON
} from '@constant/authEnum/ruleEngine'
import AddOrEditRiskClassModal from './AddOrEditRiskClassModal'
import ContainerPanel from '@components/ContainerPanel'
import ListPageLayout from '@layout/ListPageLayout'
import SearchPanel from './SearchPanel'

import { DEFAULT_PAGES_SIZE } from '@config'
import {
    AUDIT_STATE_MAP,
    BUSINESS_TYPE_MAP,
    DATA_CHECK_MODEL_AUDIT_STATE_PASS
} from '@constant/enum'
import { ERROR_COMMIT_CLOSE, ERROR_COMMIT_DELETE, ERROR_COMMIT_OPEN } from '@constant/error'
import tableShowTotal from '@mixins/tableShowTotal'
import enumOperate from '@mixins/enumOperate'

import getRiskClassListService from '@service/ruleEngine/riskClass/getRiskClassListService'
import deleteRiskClassService from '@service/ruleEngine/riskClass/deleteRiskClassService'
import { scrollConfig } from '@constant/Const'
import switchRiskClassService from '@service/ruleEngine/riskClass/switchRiskClassService'
import { open } from '@utils'

const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        align: 'left',
        width: 100,
        key: 'index',
        customRender: (v, r, idx) => ++idx
    },
    {
        title: '模型名称',
        dataIndex: 'modelExamplesName',
        align: 'left',
        width: 200,
        key: 'modelExamplesName'
    },
    {
        title: '业务类型',
        dataIndex: 'riskBusinessType',
        align: 'left',
        width: 200,
        key: 'riskBusinessType',
        customRender: v => BUSINESS_TYPE_MAP[v]
    },
    {
        title: '审核状态',
        dataIndex: 'auditState',
        align: 'left',
        width: 140,
        key: 'auditState',
        customRender: v => AUDIT_STATE_MAP[v]
    },
    {
        title: '是否启用',
        dataIndex: 'state',
        align: 'left',
        scopedSlots: {
            customRender: 'state'
        },
        width: 200,
        key: 'state'
    },
    {
        title: '更新时间',
        dataIndex: 'modifyDateTime',
        align: 'left',
        width: 200,
        key: 'modifyDateTime'
    },
    {
        title: '操作',
        key: 'action',
        align: 'left',
        width: 200,
        scopedSlots: {
            customRender: 'action'
        }
    }
]
export default {
    name: 'RiskClass',
    components: {
        ListPageLayout,
        ContainerPanel,
        AddOrEditRiskClassModal,
        AButton,
        ATable,
        SearchPanel,
        ASwitch
    },
    mixins: [tableShowTotal, enumOperate],
    data() {
        return {
            RULE_ENGINE_ADD_RISK_CLASS_BUTTON,
            RULE_ENGINE_DELETE_RISK_CLASS_BUTTON,
            RULE_ENGINE_EDIT_RISK_CLASS_BUTTON,
            DATA_CHECK_MODEL_AUDIT_STATE_PASS,
            RULE_ENGINE_LOOK_RISK_CLASS_INFO_BUTTON,
            columns,
            params: {},
            dataList: [],
            limit: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            loading: false,
            visible: false,
            info: {},
            mode: '',
            scrollConfig
        }
    },
    computed: {
        pagination() {
            const { total, limit, current, tableShowTotal } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        }
    },
    created() {
        this.getList()
    },
    methods: {
        async handleSwitch(value, record) {
            try {
                const params = {
                    enable: +value,
                    id: record.id
                }
                await switchRiskClassService(params)
                let message = value ? ERROR_COMMIT_OPEN : ERROR_COMMIT_CLOSE
                this.$message.success(message)
                this.getList()
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        },
        handleAdd() {
            this.mode = 'create'
            this.visible = true
        },
        // 添加成功
        handleSuccess() {
            this.visible = false
            this.getList()
        },
        handleCancel() {
            this.visible = false
        },
        handleLook({ id }) {
            open.call(this, 'RiskClassInfo', {
                id
            })
        },
        handleEdit(action) {
            this.mode = 'edit'
            this.visible = true
            this.info = action
        },
        handleDelete(action) {
            this.$confirm({
                title: '确认删除当前风险等级吗？',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk: () => {
                    this.deleteService(action)
                },
                onCancel() {
                }
            })
        },
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        async deleteService(action) {
            try {
                let { id } = action
                await deleteRiskClassService(id)
                this.$message.success(ERROR_COMMIT_DELETE)
                this.getList()
            } catch (e) {
                this.$message.error(e.message)
            }
        },
        handleSearch(params) {
            this.params = params
            this.current = 1
            this.getList()
        },
        async getList() {
            try {
                this.loading = true
                const { current, limit, params } = this
                const param = {
                    current,
                    size: limit,
                    ...params
                }
                const { data } = await getRiskClassListService(param)
                this.dataList = data.records
                this.current = data.current
                this.total = data.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
