<template>
    <a-form-model
        ref="ruleForm"
        class="add-edit-category-form-panel"
        layout="horizontal"
        :label-col="{ span: 6}"
        :wrapper-col="{ span: 16 }"
        :model="form"
        :rules="rules">
        <a-form-model-item label="风险评估模型名称" prop="modelExamplesName">
            <a-input
                v-model.trim="form.modelExamplesName"
                :max-length="INPUT_MAX_LENGTH"
                placeholder="请输入风险评估模型名称" />
        </a-form-model-item>
        <a-form-model-item label="模型描述" prop="description">
            <a-input
                v-model.trim="form.description"
                :max-length="INPUT_AREA_MAX_LENGTH"
                type="textarea"
                :rows="3"
                placeholder="请输入模型描述" />
        </a-form-model-item>
        <a-form-model-item label="业务类型" prop="riskBusinessType">
            <risk-business-type
                v-model="form.riskBusinessType" />
        </a-form-model-item>
        <a-form-model-item label="产品分类" prop="riskCategoryId">
            <product-category-cascader v-model="form.riskCategoryId" />
        </a-form-model-item>
    </a-form-model>
</template>

<script>
import {
    FormModel as AFormModel, Input as AInput
} from 'ant-design-vue'
import RiskBusinessType from '@weights/RiskBusinessType'
import ProductCategoryCascader from '@weights/ProductCategoryCascader'

import InitForm from '@mixins/initForm'

import { INPUT_MAX_LENGTH, INPUT_AREA_MAX_LENGTH } from '@constant/Const'
import { ERROR_COMMIT_EDIT, ERROR_COMMIT_SUCCESS } from '@constant/error'

import addRiskAssessmentModelService from '@service/ruleEngine/riskAssessmentModel/addRiskAssessmentModelService'
import editRiskAssessmentModelService from '@service/ruleEngine/riskAssessmentModel/editRiskAssessmentModelService'

export default {
    name: 'FormPanel',
    components: {
        RiskBusinessType,
        ProductCategoryCascader,
        AFormModel,
        AInput,
        AFormModelItem: AFormModel.Item
    },
    mixins: [InitForm],
    props: {
        mode: {
            type: String,
            default() {
                return ''
            }
        },
        info: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            INPUT_MAX_LENGTH,
            INPUT_AREA_MAX_LENGTH,
            form: {
                modelExamplesName: '',
                riskBusinessType: '',
                description: '',
                riskCategoryId: '',
                id: ''
            },
            rules: {
                modelExamplesName: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入风险评估模型名称'
                    }
                ],
                riskBusinessType: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请选择业务类型'
                    }
                ],
                riskCategoryId: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请选择产品分类'
                    }
                ]
            }
        }
    },
    computed: {
        isAdd() {
            return this.mode === 'create'
        },
        serviceFn() {
            const { isAdd } = this
            return isAdd ? addRiskAssessmentModelService : editRiskAssessmentModelService
        },
        commitMsg() {
            const { isAdd } = this
            return isAdd ? ERROR_COMMIT_SUCCESS : ERROR_COMMIT_EDIT
        }
    },
    watch: {
        info: {
            handler() {
                let { info, form } = this
                this.initForm(info, form)
            },
            immediate: true
        }
    },
    methods: {
        handleSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.submitService()
                }
            })
        },
        async submitService() {
            try {
                const { serviceFn, commitMsg, form } = this
                this.$emit('loading', true)
                await serviceFn(form)
                this.$message.success(commitMsg)
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.$emit('loading', false)
            }

        }


    }
}
</script>

<style lang='scss'>

</style>
