var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form-model',{ref:"ruleForm",staticClass:"risk-class-row",attrs:{"model":_vm.form}},[_c('a-table',{attrs:{"columns":_vm.trs,"data-source":_vm.form.riskScoreConfig,"pagination":false,"row-key":"_id","scroll":{
            x: 1100,
            y: 500
        }},scopedSlots:_vm._u([{key:"sort",fn:function(text,action,idx){return [_c('a-form-model-item',[_vm._v(" "+_vm._s(idx + 1)+" ")])]}},{key:"riskClass",fn:function(text,action,idx){return [_c('a-form-model-item',{attrs:{"prop":`riskScoreConfig[${idx}].name`,"rules":_vm.attributeClassNameRules}},[_c('a-input',{attrs:{"placeholder":"输入风险等级"},model:{value:(_vm.form.riskScoreConfig[idx].name),callback:function ($$v) {_vm.$set(_vm.form.riskScoreConfig[idx], "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.riskScoreConfig[idx].name"}})],1)]}},{key:"section",fn:function(text,action,idx){return [_c('div',{staticClass:"risk-class-row__rows"},[_c('a-form-model-item',{attrs:{"prop":`riskScoreConfig[${idx}].minValueComparator`,"rules":[{required: true,
                              message: '请选择',
                              trigger: 'blur',}]}},[_c('min-comparator',{model:{value:(_vm.form.riskScoreConfig[idx].minValueComparator),callback:function ($$v) {_vm.$set(_vm.form.riskScoreConfig[idx], "minValueComparator", $$v)},expression:"form.riskScoreConfig[idx].minValueComparator"}})],1),_c('a-form-model-item',{attrs:{"prop":`riskScoreConfig[${idx}].min`,"rules":_vm.attributeScoreRules}},[_c('a-input',{attrs:{"placeholder":"输入分值"},model:{value:(_vm.form.riskScoreConfig[idx].min),callback:function ($$v) {_vm.$set(_vm.form.riskScoreConfig[idx], "min", $$v)},expression:"form.riskScoreConfig[idx].min"}})],1),_c('a-form-model-item',[_vm._v("~")]),_c('a-form-model-item',{attrs:{"prop":`riskScoreConfig[${idx}].maxValueComparator`,"rules":[{required: true,
                              message: '请选择',
                              trigger: 'blur',}]}},[_c('max-comparator',{model:{value:(_vm.form.riskScoreConfig[idx].maxValueComparator),callback:function ($$v) {_vm.$set(_vm.form.riskScoreConfig[idx], "maxValueComparator", $$v)},expression:"form.riskScoreConfig[idx].maxValueComparator"}})],1),_c('a-form-model-item',{attrs:{"prop":`riskScoreConfig[${idx}].max`,"rules":_vm.attributeScoreRules}},[_c('a-input',{attrs:{"placeholder":"输入分值"},model:{value:(_vm.form.riskScoreConfig[idx].max),callback:function ($$v) {_vm.$set(_vm.form.riskScoreConfig[idx], "max", $$v)},expression:"form.riskScoreConfig[idx].max"}})],1)],1)]}},{key:"levelDesc",fn:function(text,action,idx){return [_c('a-form-model-item',{attrs:{"prop":`riskScoreConfig[${idx}].levelDesc`,"rules":[{
                    required: true,
                    message: '请输入等级描述',
                    trigger: 'blur',
                }]}},[_c('a-input',{attrs:{"max-length":_vm.INPUT_MAX_LENGTH,"placeholder":"输入等级描述"},model:{value:(_vm.form.riskScoreConfig[idx].levelDesc),callback:function ($$v) {_vm.$set(_vm.form.riskScoreConfig[idx], "levelDesc", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.riskScoreConfig[idx].levelDesc"}})],1)]}},{key:"levelImg",fn:function(text,action,idx){return [_c('a-form-model-item',{attrs:{"prop":`riskScoreConfig[${idx}].levelImg`,"rules":[{
                    required: true,
                    message: '请选择等级标识图',
                    trigger: 'blur',
                }]}},[_c('upload-in-table',{attrs:{"size":10,"show-img":true,"text":"上传"},model:{value:(_vm.form.riskScoreConfig[idx].levelImg),callback:function ($$v) {_vm.$set(_vm.form.riskScoreConfig[idx], "levelImg", $$v)},expression:"form.riskScoreConfig[idx].levelImg"}})],1)]}},{key:"action",fn:function(text,action,idx){return [_c('a-form-model-item',[_c('a-button',{directives:[{name:"show",rawName:"v-show",value:(idx!==0),expression:"idx!==0"}],on:{"click":function($event){return _vm.handleDelete(idx)}}},[_vm._v(" 删除 ")])],1)]}}])}),_c('a-button',{staticClass:"risk-class-row__add-button",attrs:{"type":"primary"},on:{"click":_vm.handleAdd}},[_vm._v(" 添加风险等级 ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }