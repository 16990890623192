<template>
    <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules">
        <table>
            <colgroup>
                <col style="width: 100px; min-width: 100px;">
                <col style="width: 200px; min-width: 200px;">
                <col style="width: 200px; min-width: 200px;">
                <col style="width: 300px; min-width: 300px;">
                <col style="width: 150px; min-width: 150px;">
                <col style="width: 200px; min-width: 200px;">
            </colgroup>
            <tbody>
                <tr class="master-field-attribute">
                    <td>
                        <a-form-model-item>{{ index + 1 }}</a-form-model-item>
                    </td>
                    <td>
                        <a-form-model-item prop="masterDataId">
                            <!-- 中文名回显 -->
                            <a-select
                                v-if="disabled"
                                v-model="fieldInfo.masterDataName"
                                :disabled="disabled" />
                            <risk-obj-select
                                v-else
                                v-model="form.masterDataId"
                                :disabled="disabled"
                                @option="handleOption" />
                        </a-form-model-item>
                    </td>
                    <td>
                        <!-- 中文名回显 -->
                        <a-form-model-item
                            v-if="disabled">
                            <a-select
                                v-model="fieldInfo.masterDataClassificationName"
                                :disabled="disabled" />
                        </a-form-model-item>
                        <a-form-model-item
                            v-else
                            prop="formCategoryArr">
                            <risk-category-select
                                v-model="form.formCategoryArr"
                                :option="masterOption"
                                :category-id="fieldInfo.masterDataClassificationId"
                                :disabled="disabled"
                                @change="handleCategoryChange" />
                        </a-form-model-item>
                    </td>
                    <td>
                        <a-form-model-item prop="riskFactorDefinitionId">
                            <!-- 修改时中文名回显 不掉接口 -->
                            <a-select
                                v-if="disabled"
                                v-model="fieldInfo.factorName"
                                :disabled="disabled" />
                            <a-select
                                v-else
                                v-model="form.riskFactorDefinitionId"
                                show-search
                                placeholder="请选择"
                                option-filter-prop="children"
                                @change="handleFactorIdChange">
                                <a-select-option
                                    v-for="item in factorDataList"
                                    :key="item.id"
                                    :filter-option="false"
                                    :value="item.id"
                                    :label="item.factorName">
                                    {{ item.factorName }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </td>
                    <td>
                        <a-form-model-item>
                            <a-radio-group v-model="form.isFormula" :disabled="isLook">
                                <a-radio :value="true">
                                    是
                                </a-radio>
                                <a-radio :value="false">
                                    否
                                </a-radio>
                            </a-radio-group>
                        </a-form-model-item>
                    </td>
                    <td>
                        <a-form-model-item class="action-button">
                            <a-button
                                v-show="form.isFormula===true"
                                type="link"
                                @click="handleSet">
                                <span v-show="isLook">查看计算公式</span>
                                <span v-show="!isLook">设置计算公式</span>
                            </a-button>
                            <a-button
                                v-show="!isLook"
                                type="link"
                                @click="handleDelete">
                                删除
                            </a-button>
                        </a-form-model-item>
                    </td>
                </tr>

                <set-computed-formula-modal
                    :is-look="isLook"
                    :selected-formula-obj="selectedFormulaObj"
                    :factor-data-item="factorDataItem"
                    :visible="visible"
                    @cancel="handleCancel"
                    @success="handleSuccess"
                />
            </tbody>
        </table>
    </a-form-model>
</template>

<script>
import {
    Radio as ARadio, Button as AButton, FormModel as AFormModel, Select as ASelect
} from 'ant-design-vue'
import RiskObjSelect from '@weights/GbCloud/RiskObjSelect'
import RiskCategorySelect from '@weights/GbCloud/RiskCategorySelect'
import SetComputedFormulaModal from '@weights/SetComputedFormulaModal'

import getRiskAssessmentFactorListService
from '@service/ruleEngine/riskAssessmentModel/getRiskAssessmentFactorListService'
import InitForm from '@mixins/initForm'

export default {
    name: 'InsertAssessmentField',
    components: {
        ARadio,
        ARadioGroup: ARadio.Group,
        ASelect,
        ASelectOption: ASelect.Option,
        RiskObjSelect,
        RiskCategorySelect,
        AButton,
        SetComputedFormulaModal,
        AFormModel,
        AFormModelItem: AFormModel.Item
    },
    mixins: [InitForm],
    props: {
        fieldInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        index: {
            type: Number,
            default: 0
        },
        fieldData: {
            type: Array,
            default() {
                return []
            }
        },
        isLook: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            masterOption: null,
            categoryOption: null,
            factorDataList: [],
            form: {
                id: '',
                masterDataId: '',
                // masterDataClassificationId: '',
                categoryId: '',
                formCategoryArr: [],
                riskFactorDefinitionId: '',
                riskAssessmentModelId: '', // 风险评估模型id
                isFormula: false
            },
            rules: {
                masterDataId: [
                    {
                        required: true,
                        message: '请选择风险对象',
                        trigger: 'blur'
                    }
                ],
                formCategoryArr: [
                    {
                        required: true,
                        message: '请选择风险分类',
                        trigger: 'blur'
                    }
                ],
                riskFactorDefinitionId: [
                    {
                        required: true,
                        message: '请选择风险因子',
                        trigger: 'blur'
                    }
                ]
            },
            visible: false,
            riskObjDataList: null, // 风险对象dataList ->回显使用
            selectedFormulaObj: null, // 设置的公式
            factorDataItem: null,
            selectFactorFormula: ''// 选择的因子对应的默认内容显示
        }
    },
    computed: {
    // 因子id 作修改使用
        id() {
            return this.$route.query.id || ''
        },
        queryId() {
            return this.$route.query.queryId
        },
        params() {
            // eslint-disable-next-line no-unused-vars
            const { formCategoryArr, ...params } = this.form
            return params
        },
        disabled() {
            const { fieldInfo } = this
            return !!fieldInfo.id
        }
    },
    watch: {
        masterOption(val) {
            this.form.source = val.source
        },
        'form.categoryId': {
            handler() {
                if (this.disabled) return
                this.getCanAddRiskFactor()
            }
        },
        factorDataList: {
            handler(val) {
                const { id, form } = this
                if (id && form.riskFactorDefinitionId) { // 回显修改的属性值信息
                    this.factorDataItem = val.find(item => item.id === form.riskFactorDefinitionId)
                }
            }
        },
        fieldInfo: {
            handler(val) {
                const { masterDataClassificationId, masterDataId, source } = val
                this.masterOption = {
                    masterDataId,
                    source
                }
                const { fieldInfo, form } = this
                this.initForm(fieldInfo, form)
                this.form.categoryId = masterDataClassificationId

                // 修改回显赋值 删除时使用
                this.selectFactorFormula = this.getFactorFormula(val)

                if (fieldInfo.isFormula) {
                    const { formulaDescription, formulaDisplay } = fieldInfo
                    this.selectedFormulaObj = {
                        formulaDescription,
                        formulaDisplay
                    }
                }
            },
            immediate: true
        }
    },
    created() {
        this.form.riskAssessmentModelId = this.queryId
    },
    methods: {
    // 拼接因子公式
        getFactorFormula(val) {
            const { masterDataClassificationId, factorCode } = val
            return `$\{${masterDataClassificationId}.${factorCode}}`
        },
        handleFactorIdChange(val) {
            if (!val) return
            this.factorDataItem = this.factorDataList.find(item => item.id === val)

            this.selectFactorFormula = this.getFactorFormula(this.factorDataItem)

            this.$emit('factor-select', this.selectFactorFormula)
        },
        // showDeleteButton (){
        //   const {fieldData} = this
        //   return fieldData.length > 1
        // },
        handleFormReset() {
            this.$refs.ruleForm.resetFields()
        },
        handleFormValidate() {
            let res = null
            const { params, selectedFormulaObj } = this
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    res = params
                    if (params.isFormula) {
                        if (!selectedFormulaObj) {
                            this.$message.error('请输入计算公式')
                            return
                        }
                        Object.assign(res, selectedFormulaObj)
                    }
                }
            })
            return res
        },
        handleDelete() {
            this.$emit('delete', this.index, this.selectFactorFormula)
        },
        handleSet() {
            this.visible = true
        },
        handleSuccess(res) {
            this.selectedFormulaObj = res
            this.visible = false
        },
        handleCancel() {
            this.visible = false
        },
        handleOption(option) {
            this.masterOption = option
        },
        handleCategoryChange(val) {
            // this.form.masterDataClassificationId = val
            this.form.categoryId = val
            this.form.riskFactorDefinitionId = ''// 清空下级关联内容
            this.factorDataList = []
        },
        async getCanAddRiskFactor() {
            try {
                const { params } = this
                this.factorDataList = await getRiskAssessmentFactorListService(params)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        }
    }
}
</script>

<style lang='scss'>
.master-field-attribute {
  td {
    min-width: 100px;
    word-break: break-all;
    padding: 0 10px;
    text-align: left;
  }

  .action-button {
    .ant-btn {
      padding: 0 10px 0 0;
    }
  }
}
</style>
