<template>
    <a-form-model
        ref="ruleForm"
        class="add-edit-category-form-panel"
        layout="horizontal"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
        :model="form"
        :rules="rules[mode]">
        <a-form-model-item label="地区" prop="areaCode">
            <area-cascader
                v-if="isAdd"
                v-model="form.areaCode"
                ref="areaRef"
                :show-county="false"
                :is-filter="true" />
            <div v-else>{{ form.provinceName }}<span v-if="form.cityName">-</span>{{ form.cityName }}</div>
        </a-form-model-item>
        <a-form-model-item label="配置默认内容" prop="content">
            <div v-if="isLook">{{ form.content }}</div>
            <a-input
                v-else
                v-model.trim="form.content"
                :max-length="INPUT_AREA_CONTENT_MAX_LENGTH"
                :rows="6"
                type="textarea"
                placegolder="请输入配置默认内容" />
        </a-form-model-item>
    </a-form-model>
</template>

<script>
import {
    FormModel as AFormModel, Input as AInput
} from 'ant-design-vue'

import { ERROR_COMMIT_SUCCESS } from '@constant/error'

import getCloudRiskFactorService from '@service/gbCloud/getCloudRiskFactorService'
import addRiskFactorService from '@service/ruleEngine/riskFactor/addRistFactorService'
import InitForm from '@mixins/initForm'
import { INPUT_AREA_CONTENT_MAX_LENGTH } from '@constant/Const'

export default {
    name: 'FormPanel',
    components: {
        AreaCascader: () => import('@weights/AreaCascader'),
        AFormModel,
        AFormModelItem: AFormModel.Item,
        AInput
    },
    mixins: [InitForm],
    props: {
    // 选的所有的地区
        areaList: {
            type: Array,
            default() {
                return []
            }
        },
        mode: {
            type: String,
            default: ''
        },
        info: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        const areaValidator = (rule, value, callback) => {
            if (value.length !== 2) {
                callback('地区请选择到市')
            } else {
                callback()
            }
        }
        const rules = {
            look: {},
            create: {
                areaCode: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请选择'
                    }, {
                        validator: areaValidator
                    }
                ],
                content: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入'
                    }
                ]
            },
            edit: {
                content: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入'
                    }
                ]
            }
        }
        return {
            INPUT_AREA_CONTENT_MAX_LENGTH,
            form: {
                areaCode: [],
                content: '',
                riskAssessmentContentId: '',
                riskAssessmentModelId: '',
                cityName: '',
                provinceName: ''
            },
            rules, // 动态rules
            areaMessage: '地区选择错误'
        }
    },
    computed: {
        isLook() {
            const { mode } = this
            return mode === 'look'
        },
        isAdd() {
            const { mode } = this
            return mode === 'create'
        },
        isEdit() {
            const { mode } = this
            return mode === 'edit'
        },
        // 模型id
        queryId() {
            return this.$route.query.queryId
        },
        params() {
            const { form } = this
            const { mapArea } = this.$refs.areaRef
            let params = {
                ...form
            }
            const [provinceCode, cityCode] = params.areaCode
            params.provinceName = mapArea.get(provinceCode).label
            params.cityName = mapArea.get(cityCode).label
            params.areaCode = cityCode // 后台接收的地区code
            params.key = cityCode
            return params
        }
    },
    watch: {
        info: {
            handler(val) {
                let { form } = this
                this.initForm(val, form)
            },
            immediate: true
        }
    },
    created() {
        this.form.riskAssessmentModelId = this.queryId
    },
    methods: {
        handleSubmit() {
            const { isAdd, isEdit, form, areaList } = this
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    if (isAdd) {
                        const { params, areaMessage } = this
                        const { mapArea } = this.$refs.areaRef
                        const item = mapArea.get(params.areaCode)
                        let res = []
                        if (item.parentId) {
                            res.push(mapArea.get(item.parentId))
                        } else {
                            res = item.children.map(item => item.value)
                        }
                        res.push(params.areaCode)
                        // res不能存在areaList
                        for (let i = 0; i < areaList.length; i++) {
                            const selectItem = areaList[i]
                            if (res.find(resItem => resItem === selectItem.areaCode)) {
                                this.$message.error(areaMessage)
                                return
                            }
                        }
                        this.$emit('success', params)
                    } else if (isEdit) {
                        this.$emit('edit', form)
                    }

                }
            })
        },
        async getFactorList() {
            try {
                const { masterDataId, source } = this.masterOption
                const { categoryId } = this
                this.factorList = await getCloudRiskFactorService({
                    masterDataId,
                    source,
                    categoryId
                })
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        },
        async submitService() {
            const { masterOption, categoryId } = this
            const factorCodes = this.form.riskFactor
            let obj = {} // 接口参数
            const { masterDataId, source } = masterOption
            obj = {
                masterDataId,
                source,
                categoryId
            }
            obj.factorCodes = factorCodes.join(',') // 因子ids
            try {
                this.$emit('loading', true)
                await addRiskFactorService(obj)
                this.$message.success(ERROR_COMMIT_SUCCESS)
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.$emit('loading', false)
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
