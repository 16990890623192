<template>
    <a-form-model
        ref="ruleForm"
        class="add-edit-category-form-panel"
        layout="horizontal"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
        :model="form"
        :rules="rules">
        <a-form-model-item label="智能评估模型名称" prop="modelExamplesName">
            <a-input v-model.trim="form.modelExamplesName" :max-length="INPUT_MAX_LENGTH" placeholder="请输入智能评估模型名称" />
        </a-form-model-item>
        <a-form-model-item label="模型描述" prop="description">
            <a-input v-model.trim="form.description" type="textarea" :max-length="INPUT_MAX_LENGTH" placeholder="请输入模型描述" />
        </a-form-model-item>

        <a-form-model-item label="业务类型" prop="riskBusinessType">
            <risk-business-type
                v-model="form.riskBusinessType" />
        </a-form-model-item>

        <a-form-model-item label="产品分类" prop="riskCategoryId">
            <product-category-cascader v-model="form.riskCategoryId" />
        </a-form-model-item>

        <a-form-model-item label="保费" prop="productPremium">
            <div style="display: flex;">
                <max-comparator v-model="form.premiumOperator" style="width: 200px; margin-right: 10px" />
                <a-input v-model="form.productPremium" placeholder="请输入" />
            </div>
        </a-form-model-item>
        <a-form-model-item label="风险等级模型" prop="riskScoreModelId">
            <risk-class-model
                v-model="form.riskScoreModelId"
                :risk-business-type="form.riskBusinessType" />
        </a-form-model-item>
        <a-form-model-item label="风险等级" prop="riskLevel">
            <risk-class-select
                v-model="form.riskLevel"
                :risk-score-model-id="form.riskScoreModelId" />
        </a-form-model-item>
    </a-form-model>
</template>

<script>
import {
    FormModel as AFormModel,
    Input as AInput
} from 'ant-design-vue'
import RiskBusinessType from '@weights/RiskBusinessType'
import ProductCategoryCascader from '@weights/ProductCategoryCascader'

import { ERROR_COMMIT_SUCCESS, ERROR_COMMIT_EDIT } from '@constant/error'
import InitForm from '@mixins/initForm'

import addSmartAssessModelService from '@service/ruleEngine/smartAssessModel/addSmartAssessModelService'
import editSmartAssessModelService from '@service/ruleEngine/smartAssessModel/editSmartAssessModelService'
import { INPUT_MAX_LENGTH } from '@constant/Const'

export default {
    name: 'FormPanel',
    components: {
        AFormModel,
        AFormModelItem: AFormModel.Item,
        RiskBusinessType,
        AInput,
        ProductCategoryCascader,
        MaxComparator: () => import('@weights/Comparator/MaxComparator'),
        RiskClassModel: () => import('@weights/RiskClass/RiskClassModel'),
        RiskClassSelect: () => import('@weights/RiskClass/RiskClassSelect')
    },
    mixins: [InitForm],
    props: {
        mode: {
            type: String,
            default() {
                return ''
            }
        },
        info: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            INPUT_MAX_LENGTH,
            form: {
                modelExamplesName: '',
                description: '',
                riskCategoryId: '',
                riskBusinessType: '',
                id: '',
                premiumOperator: undefined, // 运算符
                productPremium: '', // 保费
                riskScoreModelId: '', // 风险等级模型id
                riskLevel: []// 风险等级
            },
            rules: {
                modelExamplesName: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入分类'
                    }
                ],
                riskCategoryId: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请选择风险分类'
                    }
                ],
                riskBusinessType: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请选择业务类型'
                    }
                ],
                productPremium: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请输入保费'
                    }
                ],
                riskScoreModelId: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '请选择风险等级模型'
                    }
                ],
                riskLevel: [
                    {
                        required: true,
                        trigger: 'blur',
                        message: '风险等级'
                    }
                ]
            }
        }
    },
    computed: {
        isAdd() {
            return this.mode === 'create'
        },
        serviceFn() {
            const { isAdd } = this
            return isAdd ? addSmartAssessModelService : editSmartAssessModelService
        },
        commitMsg() {
            const { isAdd } = this
            return isAdd ? ERROR_COMMIT_SUCCESS : ERROR_COMMIT_EDIT
        }
    },
    watch: {
        info: {
            handler(val) {
                if (!val.id) return
                let { info, form } = this
                this.initForm(info, form)
                this.form.riskLevel = info.riskLevel.split('|')
            },
            immediate: true
        }
    },
    methods: {
        handleSubmit() {
            // let params = JSON.parse(JSON.stringify(this.form))
            const { riskLevel, ...params } = this.form
            const param = {
                ...params,
                riskLevel: riskLevel.join('|')
            }
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.addOrEditDataCheckModel(param)
                }
            })
        },
        async addOrEditDataCheckModel(params) {
            try {
                const { serviceFn, commitMsg } = this
                this.$emit('loading', true)
                await serviceFn(params)
                this.$message.success(commitMsg)
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.$emit('loading', false)
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
