<template>
    <page-layout class="risk-assessment-content">
        <page-panel>
            <a-form-model
                ref="ruleForm"
                :model="form"
                :rules="rules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol">
                <a-form-model-item label="布局分类" prop="layoutId">
                    <layout-category
                        v-model="form.layoutId"
                        :disabled="isLook"
                        :layout-type="RISK_ASSESS_MODEL_LAYOUT_TYPE"
                        :object-id="$route.query.id"
                        :max-page-size="true" />
                </a-form-model-item>
                <a-form-model-item label="风险对象" prop="masterDataId">
                    <risk-obj-select
                        v-model="form.masterDataId"
                        :disabled="isDisabled"
                        @option="handleOption" />
                </a-form-model-item>
                <a-form-model-item label="风险分类" prop="formCategoryArr">
                    <risk-category-select
                        v-model="form.formCategoryArr"
                        :option="masterOption"
                        :disabled="isDisabled"
                        :category-id="contentInfo.masterDataClassificationId"
                        @change="handleCategoryChange" />
                </a-form-model-item>
                <a-form-model-item label="评估内容" prop="riskFactorDefinitionId">
                    <a-select
                        v-model="form.riskFactorDefinitionId"
                        :disabled="isDisabled"
                        show-search
                        placeholder="请选择评估内容"
                        option-filter-prop="children">
                        <a-select-option
                            v-for="item in factorDataList"
                            :key="item.id"
                            :filter-option="false"
                            :value="item.id"
                            :label="item.factorName">
                            {{ item.factorName }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="评估内容类型" prop="contentType">
                    <div style="display: flex">
                        <a-select
                            v-model="form.contentType"
                            :disabled="isDisabled"
                            placeholder="请选择">
                            <a-select-option
                                v-for="item in RISK_ASSESSMENT_CONTENT_TYPE_ENUM_LIST"
                                :key="item.value"
                                :value="item.value">
                                {{ item.label }}
                            </a-select-option>
                        </a-select>
                        <custom-button
                            v-show="showAddCity"
                            type="reset"
                            style="margin-left: 4px;"
                            text="添加地区"
                            @click="handleAddArea" />
                    </div>
                </a-form-model-item>

                <!-- 全国业务面板 -->
                <all-can-used
                    v-show="showAll"
                    ref="allRef"
                    :is-look="isLook"
                    :content-info="contentInfo" />
                <!-- 城市业务面板 -->
                <city-can-used
                    v-show="showCity"
                    ref="cityRef"
                    :is-look="isLook"
                    :content-info="contentInfo" />

                <a-form-model-item :wrapper-col="{ span: 8, offset: 10 }">
                    <a-button type="default" @click="handleCancel">取消</a-button>
                    <a-button v-show="!isLook"
                              type="primary"
                              :loading="loading"
                              style="margin-left: 20px;"
                              @click="handleSubmit">
                        提交
                    </a-button>
                </a-form-model-item>
            </a-form-model>
        </page-panel>
    </page-layout>
</template>

<script>
import {
    FormModel as AFormModel,
    Button as AButton, Select as ASelect
} from 'ant-design-vue'
import AllCanUsed from './AllCanUsed'
import CityCanUsed from './CityCanUsed'
import PagePanel from '@components/PagePanel'
import PageLayout from '@layout/PageLayout'
import LayoutCategory from '@weights/LayoutCategory'
import RiskObjSelect from '@weights/GbCloud/RiskObjSelect'
import RiskCategorySelect from '@weights/GbCloud/RiskCategorySelect'
import CustomButton from '@components/Button'

import getRiskAssessmentFactorListService
from '@service/ruleEngine/riskAssessmentModel/getRiskAssessmentFactorListService'
import getRiskAssessmentModelContentInfoService
from '@service/ruleEngine/riskAssessmentModel/getRiskAssessmentModelContentInfoService'
import addRiskAssessmentModelContentService
from '@service/ruleEngine/riskAssessmentModel/addRiskAssessmentModelContentService'
import editRiskAssessmentModelContentService
from '@service/ruleEngine/riskAssessmentModel/editRiskAssessmentModelContentService'
import { DEFAULT_PAGES_SIZE } from '@config'
import { ERROR_COMMIT_SUCCESS, ERROR_COMMIT_EDIT } from '@constant/error'
import {
    RISK_ASSESS_MODEL_LAYOUT_TYPE, RISK_ASSESSMENT_CONTENT_TYPE_ENUM_LIST,
    RISK_ASSESSMENT_CONTENT_ALL_USED_TYPE, RISK_ASSESSMENT_CONTENT_CITY_USED_TYPE
} from '@constant/enum'
import InitForm from '@mixins/initForm'

export default {
    name: 'AddRiskFactor',
    components: {
        AllCanUsed,
        CityCanUsed,
        PageLayout,
        LayoutCategory,
        AFormModel,
        AFormModelItem: AFormModel.Item,
        PagePanel,
        AButton,
        RiskObjSelect,
        RiskCategorySelect,
        ASelect,
        ASelectOption: ASelect.Option,
        CustomButton
    },
    mixins: [InitForm],
    data() {
        return {
            RISK_ASSESS_MODEL_LAYOUT_TYPE,
            RISK_ASSESSMENT_CONTENT_ALL_USED_TYPE, // 全国 1
            RISK_ASSESSMENT_CONTENT_CITY_USED_TYPE, // 城市 2
            RISK_ASSESSMENT_CONTENT_TYPE_ENUM_LIST,
            labelCol: {
                span: 3
            },
            wrapperCol: {
                span: 10
            },
            form: {
                id: '',
                layoutId: undefined,
                layoutType: RISK_ASSESS_MODEL_LAYOUT_TYPE, // 布局类型 风险评估模型布局 2
                masterDataId: '',
                formCategoryArr: [],
                categoryId: '',
                source: '',
                riskFactorDefinitionId: undefined, // 风险因子id
                riskAssessmentModelId: '', // 风险评估模型id
                contentType: 1 // 类型 全国/地区
                // masterDataClassificationId:'',
            },
            loading: false,
            masterOption: null, // 风险对象选择的item,
            limit: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            factorDataList: [], // 因子列表
            rules: {
                layoutId: [
                    {
                        required: true,
                        message: '请选择布局分类',
                        trigger: 'blur'
                    }
                ],
                masterDataId: [
                    {
                        required: true,
                        message: '请选择风险对象',
                        trigger: 'blur'
                    }
                ],
                formCategoryArr: [
                    {
                        required: true,
                        message: '请选择风险分类',
                        trigger: 'blur'
                    }
                ],
                riskFactorDefinitionId: [
                    {
                        required: true,
                        message: '请选择评估内容',
                        trigger: 'blur'
                    }
                ],
                contentType: [
                    {
                        required: true,
                        message: '请选择',
                        trigger: 'blur'
                    }
                ]
            },
            contentInfo: {}, // 内容详情页
            riskObjDataList: null // 风险对象dataList ->回显使用
        }
    },
    computed: {
    // 模型id
        queryId() {
            return this.$route.query.queryId
        },
        // 修改使用的id
        id() {
            return this.$route.query.id
        },
        // 是否查看
        look() {
            return +this.$route.query.look
        },
        // 修改时部分表单不可修改
        isDisabled() {
            const { id, isLook } = this
            return !!id || isLook
        },
        // 是否查看
        isLook() {
            const { look } = this
            return !!+look
        },
        params() {
            // eslint-disable-next-line no-unused-vars
            const { formCategoryArr, ...params } = this.form
            return params
        },
        childrenRef() {
            const { contentType } = this.form
            let res = ''
            if (contentType === RISK_ASSESSMENT_CONTENT_ALL_USED_TYPE) {
                res = 'allRef'
            } else if (contentType === RISK_ASSESSMENT_CONTENT_CITY_USED_TYPE) {
                res = 'cityRef'
            }
            return res
        },
        serviceFn() {
            const { id } = this
            return id ? editRiskAssessmentModelContentService : addRiskAssessmentModelContentService
        },
        commitMsg() {
            const { id } = this
            return id ? ERROR_COMMIT_EDIT : ERROR_COMMIT_SUCCESS
        },
        // 展示城市面板
        showCity() {
            const { form } = this
            return form.contentType === RISK_ASSESSMENT_CONTENT_CITY_USED_TYPE
        },
        // 展示全国面板
        showAll() {
            const { form } = this
            return form.contentType === RISK_ASSESSMENT_CONTENT_ALL_USED_TYPE
        },
        showAddCity() {
            const { showCity, look } = this
            return showCity && !+look
        }
    },
    watch: {
        masterOption(val) {
            this.form.source = val.source
        },
        'form.categoryId': {
            handler(val) {
                this.factorDataList = []
                if (val) {
                    this.getCanAddRiskFactor()
                }
                // this.form.riskFactorDefinitionId = ''//清空下级关联内容
            }
        },
        id: {
            handler(val) {
                if (!val) return
                this.getContentInfo()
            },
            immediate: true
        },
        contentInfo: {
            handler(val) {
                const { contentInfo, form } = this
                this.initForm(contentInfo, form)
                this.form.categoryId = contentInfo.masterDataClassificationId
                const { masterDataId, source } = val
                this.masterOption = {
                    masterDataId,
                    source
                }
                if (contentInfo.isFormula) {
                    const { formulaDescription, formulaDisplay } = contentInfo
                    this.selectedFormulaObj = {
                        formulaDescription,
                        formulaDisplay
                    }
                }
            }
        }
    },
    created() {
        this.form.riskAssessmentModelId = this.queryId
    },
    methods: {
        handleSearch(val) {
            this.searchName = val
        },
        handleOption(option) {
            this.masterOption = option
            this.form.categoryId = '' // 点击后再清空form关联数据
        },
        handleCategoryChange(val) {
            this.form.categoryId = val
            this.form.riskFactorDefinitionId = ''
        },
        handleAddArea() {
            this.$refs.cityRef.handleAdd()
        },
        handleCancel() {
            this.$router.back()
        },
        handleSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    const { childrenRef, params } = this
                    // 子组件的form数据
                    const childRes = this.$refs[childrenRef].handleFormValidate()
                    const paramsObj = {
                        ...params,
                        ...childRes
                    }
                    childRes && this.addService(paramsObj)
                }
            })
        },
        async addService(paramsObj) {
            try {
                this.loading = true
                const { id, serviceFn, commitMsg, childrenRef } = this
                await serviceFn(paramsObj)
                this.$message.success(commitMsg)
                this.$refs.ruleForm.resetFields()
                this.$refs[childrenRef].handleFormReset()
                if (id) {
                    // this.getContentInfo()
                    this.$router.back()
                }
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        async getContentInfo() {
            try {
                const { id } = this
                this.contentInfo = await getRiskAssessmentModelContentInfoService(id)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        },
        async getCanAddRiskFactor() {
            try {
                const { params, id } = this
                const res = await getRiskAssessmentFactorListService(params)
                this.factorDataList = id ? res : res.filter(item => item.isUsed === false)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        }
    }
}
</script>

<style lang='scss'>
.risk-assessment-content {
  .ant-form-item-label {
    min-width: 110px;
  }
  &__insert-field-container {
    &__button {
      text-align: right
    }
  }
}
</style>
