<template>
    <search-panel :model="form"
                  layout="vertical"
                  class="enterprise-list-search-panel"
                  @search="handleSearch"
                  @refresh="handleRefresh">
        <form-col :span="6" label="业务类型" prop="riskBusinessType">
            <risk-business-type
                v-model="form.riskBusinessType"
                slot="formElements" />
        </form-col>
    </search-panel>
</template>

<script>
import SearchPanel from '@components/SearchPanel'
import FormCol from '@components/FormCol'
import RiskBusinessType from '@weights/RiskBusinessType'

export default {
    name: 'RCSearchPanel',
    components: {
        SearchPanel,
        FormCol,
        RiskBusinessType
    },
    data() {
        return {
            form: {
                riskBusinessType: ''
            }
        }
    },
    methods: {
        /**
         * 响应搜索事件
         */
        handleSearch() {
            this.$emit('search', this.form)
        },
        /**
         * 响应刷新事件
         */
        handleRefresh() {
            this.$emit('refresh', this.form)
        }
    }
}
</script>

<style lang='scss'>

</style>
