<template>
    <custom-modal
        title="添加风险因子"
        :visible="visible"
        :mask-closable="false"
        :width="650"
        @close="handleCancel">
        <template slot="customFooter">
            <a-button key="reset" @click="handleCancel">
                取消
            </a-button>
            <a-button key="submit" type="primary" :loading="loading" @click="handleOk">
                提交
            </a-button>
        </template>
        <form-panel
            ref="formPanel"
            :record="record"
            @loading="handleLoading"
            @success="handleSuccess" />
    </custom-modal>
</template>

<script>
import { Button as AButton } from 'ant-design-vue'
import CustomModal from '@components/CustomModal'
import FormPanel from './FormPanel'

export default {
    name: 'AddRiskFactorModal',
    components: {
        AButton,
        CustomModal,
        FormPanel
    },
    props: {
        record: {
            type: Object,
            default() {
                return {}
            }
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false
        }
    },
    computed: {},
    methods: {
        handleLoading(value) {
            this.loading = value
        },
        handleCancel() {
            this.$emit('cancel')
        },
        handleOk() {
            this.$refs.formPanel.handleSubmit()
        },
        handleSuccess() {
            this.loading = false
            this.$emit('success')
        }
    }
}
</script>

<style lang='scss'>

</style>
