<template>
    <page-layout :show-loading="true" :loading="loading">
        <container-panel class="data-check-info-page-panel">
            <a-descriptions slot="title" :title="info.modelExamplesName" :column="3">
                <a-descriptions-item label="创建人">
                    {{ info.createName }}
                </a-descriptions-item>
                <a-descriptions-item label="更新时间">
                    {{ info.createDateTime }}
                </a-descriptions-item>
            </a-descriptions>
            <div slot="button" style="text-align: right">
                <a-space v-show="!isLook">
                    <a-button
                        v-show="canDelete"
                        v-auth="RULE_ENGINE_DELETE_SMART_ASSESS_MODEL_BUTTON"
                        @click="handleDelete">
                        删除
                    </a-button>
                    <a-button
                        v-show="canSubmitAudit"
                        v-auth="RULE_ENGINE_SUBMIT_SMART_ASSESS_MODEL_BUTTON"
                        type="primary"
                        @click="handleSubmit">
                        提交审核
                    </a-button>
                </a-space>
                <div class="data-check-info-page-panel__head-right-desc">
                    <span>状态：</span>{{ AUDIT_STATE_MAP[info.auditState] }}
                </div>
            </div>
            <!-- tabs start -->
            <tabs-panel
                :tabs-data="tabsData"
                :default-active-key="defaultActiveKey"
                @handle-tabs-change="handleTabsChange" />
            <!--      tabs end -->
        </container-panel>
        <component
            :is="defaultActiveKey"
            :info="info"
            :is-look="isLook"
            @success="handleSuccess" />
    </page-layout>
</template>

<script>
import PageLayout from '@layout/PageLayout'
import PagePanel from '@components/PagePanel'
import ContainerPanel from '@components/ContainerPanel'
import { Descriptions as ADescriptions, Button as AButton, Space as ASpace } from 'ant-design-vue'
import TabsPanel from '@components/TabsPanel'

import {
    RULE_ENGINE_DELETE_SMART_ASSESS_MODEL_BUTTON,
    RULE_ENGINE_SUBMIT_SMART_ASSESS_MODEL_BUTTON
} from '@constant/authEnum/ruleEngine'
import {
    AUDIT_STATE_MAP
} from '@constant/enum'
import { ERROR_COMMIT_DELETE, ERROR_COMMIT_SUCCESS } from '@constant/error'
import enumOperate from '@mixins/enumOperate'
import showModelButton from '@utils/showModelButton'

import getSmartAssessModelInfoService from '@service/ruleEngine/smartAssessModel/getSmartAssessModelInfoService'
import deleteSmartAssessModelService from '@service/ruleEngine/smartAssessModel/deleteSmartAssessModelService'
import submitAuditSmartAssessModelService from '@service/ruleEngine/smartAssessModel/submitAuditSmartAssessModelService'
import { actionModelConfirm } from '@utils/tip'

export default {
    name: 'SmartAssessInfo',
    components: {
        AButton,
        ASpace,
        TabsPanel,
        PageLayout,
        ADescriptions,
        PagePanel,
        ContainerPanel,
        ADescriptionsItem: ADescriptions.Item,
        BasicInfoTabPanel: () => import('./BasicInfoTabPanel')
    },
    mixins: [enumOperate],
    data() {
        return {
            RULE_ENGINE_DELETE_SMART_ASSESS_MODEL_BUTTON,
            RULE_ENGINE_SUBMIT_SMART_ASSESS_MODEL_BUTTON,
            tabsData: [
                {
                    key: 'BasicInfoTabPanel',
                    tab: '基本信息'
                }
            ],
            defaultActiveKey: 'BasicInfoTabPanel',
            info: {},
            AUDIT_STATE_MAP
        }
    },
    computed: {
        queryId() {
            return this.$route.query.id
        },
        canSubmitAudit() {
            const { info } = this
            return showModelButton.canSubmitAudit(info.auditState)
        },
        canDelete() {
            const { info } = this
            return showModelButton.canDelete(info.auditState)
        },
        isLook() {
            return !!this.$route.query.isLook
        }
    },
    created() {
        this.getInfo()
    },
    methods: {
        handleSuccess() {
            this.getInfo()
        },
        async handleDelete() {
            await actionModelConfirm.call(this, '删除当前模型，将删除当前模型的所有信息，确认删除吗？', 'danger')
            this.deleteService()
        },
        async handleSubmit() {
            await actionModelConfirm.call(this, '请仔细核对您的模型信息准确，确认提交审核吗？', 'danger')
            this.submitService()
        },
        async deleteService() {
            try {
                const { queryId } = this
                await deleteSmartAssessModelService({
                    id: queryId
                })
                this.$message.success(ERROR_COMMIT_DELETE)
                this.$router.back()
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        },
        async submitService() {
            try {
                const { queryId } = this
                await submitAuditSmartAssessModelService(queryId)
                this.$message.success(ERROR_COMMIT_SUCCESS)
                this.$router.back()
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        },
        handleTabsChange(key) {
            this.defaultActiveKey = key
        },
        async getInfo() {
            try {
                this.loading = true
                const { queryId } = this
                this.info = await getSmartAssessModelInfoService(queryId)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.data-check-info-page-panel {
    .ant-card-body {
        padding: 0 0 0 20px;
    }

    .ant-descriptions {
        border-bottom: 1px dashed $border-color;
    }

    .ant-card-head-title {
        padding: 16px 0 0;
    }

    &__head-right-desc {
        margin-top: 10px;
        text-align: right;

        span {
            color: #999
        }
    }
}
</style>
