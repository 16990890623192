<!-- 风险因子属性值弹窗 -->
<template>
    <custom-modal
        title="属性值"
        :visible="visible"
        :mask-closable="false"
        :width="650"
        @close="handleCancel">
        <template slot="customFooter">
            <div />
        </template>
        <h3>风险因子:{{ record.factorName }}</h3>
        <a-table
            :columns="columns"
            :data-source="attributes"
            :loading="loading"
            row-key="id"
            bordered>
            <template slot="index" slot-scope="text,action,index">
                {{ index + 1 }}
            </template>
        </a-table>
    </custom-modal>
</template>

<script>
import { Table as ATable } from 'ant-design-vue'
import CustomModal from '@components/CustomModal'

import getAttributesService from '@service/gbCloud/getAttributesService'

const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        align: 'left',
        key: 'index',
        width: 30,
        scopedSlots: {
            customRender: 'index'
        }
    }, {
        title: '属性值',
        dataIndex: 'name',
        align: 'left',
        key: 'name',
        width: 300
    }
]
export default {
    name: 'AttributeModal',
    components: {
        CustomModal,
        ATable
    },
    props: {
        record: {
            type: Object,
            default() {
                return {}
            }
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false,
            attributes: [],
            columns
        }
    },
    watch: {
        record: {
            handler() {
                this.getAttributes()
            }
        }
    },
    created() {

    },
    methods: {
        handleCancel() {
            this.$emit('cancel')
        },
        async getAttributes() {
            const { record } = this
            const { dataType, dictionaryCategoryId, source } = record
            try {
                this.loading = true
                this.attributes = await getAttributesService({
                    dataType,
                    dictionaryCategoryId,
                    source
                })
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
