<template>
    <a-form-model
        ref="ruleForm"
        class="risk-class-row"
        :model="form">
        <a-table
            :columns="trs"
            :data-source="form.riskScoreConfig"
            :pagination="false"
            row-key="_id"
            :scroll="{
                x: 1100,
                y: 500
            }">
            <template slot="sort" slot-scope="text,action,idx">
                <a-form-model-item>
                    {{ idx + 1 }}
                </a-form-model-item>
            </template>
            <template slot="riskClass" slot-scope="text,action,idx">
                <a-form-model-item
                    :prop="`riskScoreConfig[${idx}].name`"
                    :rules="attributeClassNameRules">
                    <a-input
                        v-model.trim="form.riskScoreConfig[idx].name"
                        placeholder="输入风险等级" />
                </a-form-model-item>
            </template>
            <template slot="section" slot-scope="text,action,idx">
                <div class="risk-class-row__rows">
                    <a-form-model-item
                        :prop="`riskScoreConfig[${idx}].minValueComparator`"
                        :rules="[{required: true,
                                  message: '请选择',
                                  trigger: 'blur',}]">
                        <min-comparator
                            v-model="form.riskScoreConfig[idx].minValueComparator" />
                    </a-form-model-item>
                    <a-form-model-item
                        :prop="`riskScoreConfig[${idx}].min`"
                        :rules="attributeScoreRules">
                        <a-input v-model="form.riskScoreConfig[idx].min" placeholder="输入分值" />
                    </a-form-model-item>
                    <a-form-model-item>~</a-form-model-item>
                    <a-form-model-item
                        :prop="`riskScoreConfig[${idx}].maxValueComparator`"
                        :rules="[{required: true,
                                  message: '请选择',
                                  trigger: 'blur',}]">
                        <max-comparator
                            v-model="form.riskScoreConfig[idx].maxValueComparator" />
                    </a-form-model-item>
                    <a-form-model-item
                        :prop="`riskScoreConfig[${idx}].max`"
                        :rules="attributeScoreRules">
                        <a-input v-model="form.riskScoreConfig[idx].max" placeholder="输入分值" />
                    </a-form-model-item>
                </div>
            </template>
            <template slot="levelDesc" slot-scope="text,action,idx">
                <a-form-model-item
                    :prop="`riskScoreConfig[${idx}].levelDesc`"
                    :rules="[{
                        required: true,
                        message: '请输入等级描述',
                        trigger: 'blur',
                    }]">
                    <a-input
                        v-model.trim="form.riskScoreConfig[idx].levelDesc"
                        :max-length="INPUT_MAX_LENGTH"
                        placeholder="输入等级描述" />
                </a-form-model-item>
            </template>
            <template slot="levelImg" slot-scope="text,action,idx">
                <a-form-model-item
                    :prop="`riskScoreConfig[${idx}].levelImg`"
                    :rules="[{
                        required: true,
                        message: '请选择等级标识图',
                        trigger: 'blur',
                    }]">
                    <upload-in-table
                        v-model="form.riskScoreConfig[idx].levelImg"
                        :size="10"
                        :show-img="true"
                        text="上传" />
                </a-form-model-item>
            </template>
            <template slot="action" slot-scope="text,action,idx">
                <a-form-model-item>
                    <a-button
                        v-show="idx!==0"
                        @click="handleDelete(idx)">
                        删除
                    </a-button>
                </a-form-model-item>
            </template>
        </a-table>
        <a-button
            class="risk-class-row__add-button"
            type="primary"
            @click="handleAdd">
            添加风险等级
        </a-button>
    </a-form-model>
</template>

<script>
import {
    Button as AButton, Input as AInput, FormModel as AFormModel,
    Table as ATable
} from 'ant-design-vue'
import { validateAttribute, validateClassName } from '@constant/validates'
import { INPUT_MAX_LENGTH } from '@constant/Const'
import { OSS_PACKAGE_TYPE_RULE_ENGINE_TYPE } from '@constant/enum'
import InitForm from '@mixins/initForm'

const trs = [
    {
        title: '序号',
        key: 'sort',
        scopedSlots: {
            customRender: 'sort'
        },
        align: 'left',
        width: 80
    },
    {
        title: '风险等级',
        key: 'riskClass',
        scopedSlots: {
            customRender: 'riskClass'
        },
        align: 'left',
        width: 260
    },
    {
        title: '数值区间',
        key: 'section',
        scopedSlots: {
            customRender: 'section'
        },
        align: 'left',
        width: 500
    },
    {
        title: '等级描述',
        key: 'levelDesc',
        scopedSlots: {
            customRender: 'levelDesc'
        },
        align: 'left',
        width: 200
    },
    {
        title: '等级标识图',
        key: 'levelImg',
        scopedSlots: {
            customRender: 'levelImg'
        },
        align: 'left',
        width: 200
    },
    {
        title: '操作',
        key: 'action',
        scopedSlots: {
            customRender: 'action'
        },
        align: 'left',
        width: 150
    }
]
const attributeClassNameRules = [
    {
        required: true,
        message: '请输入风险等级',
        trigger: 'blur'
    }, {
        validator: validateClassName
    }
]
const attributeScoreRules = [
    {
        required: true,
        message: '请输入分值',
        trigger: 'blur'
    }, {
        validator: validateAttribute
    }
]
const MIN = 0
const MAX = 100
export default {
    name: 'RiskClassRow',
    components: {
        MaxComparator: () => import('@weights/Comparator/MaxComparator'),
        MinComparator: () => import('@weights/Comparator/MinComparator'),
        UploadInTable: () => import('@weights/AppUpload/UploadInTable'),
        ATable,
        AButton,
        AInput,
        AFormModel,
        AFormModelItem: AFormModel.Item
    },
    mixins: [InitForm],
    props: {
        // 修改回显
        info: {
            type: Object,
            default() {
                return {}
            }
        },
        index: {
            type: Number,
            default: 0
        },
        // 等级列表
        rowList: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            OSS_PACKAGE_TYPE_RULE_ENGINE_TYPE,
            trs,
            MIN,
            MAX,
            form: {
                riskScoreConfig: [
                    {
                        _id: 0
                    }
                ]
            },
            attributeClassNameRules,
            attributeScoreRules,
            accept: 'image/png,image/jpeg,image/gif',
            fileList: [],
            INPUT_MAX_LENGTH
        }
    },
    watch: {
        info: {
            handler(val) {
                if (!this.$route.query.id) return
                const { form } = this
                this.initForm(val, form)
                this.form.riskScoreConfig.forEach(item => item._id = item.id)
            },
            immediate: true
        }
    },
    methods: {
        handleAdd() {
            const len = this.form.riskScoreConfig.length
            this.form.riskScoreConfig.push({
                _id: len
            })
        },
        handleDelete(index) {
            this.form.riskScoreConfig.splice(index, 1)
        },
        handleSubmit() {
            const { riskScoreConfig } = this.form
            let res = null
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    res = riskScoreConfig
                }
            })
            return res
        }
    }
}
</script>

<style lang='scss'>
.risk-class-row {
    th {
        padding: 0 20px;
    }

    td {
        padding: 10px 20px 0;
    }

    &__add-button {
        display: block;
        margin: 30px auto 0;
    }

    &__rows {
        display: flex;
        align-items: center;
        justify-content: space-around;

        > div {
            margin-right: 8px;
        }

        input {
            width: 120px
        }
    }

    .ant-upload-drag {
        width: 100px;
        height: 100px;
    }
}
</style>
