<template>
    <list-page-layout>
        <search-panel slot="header" @refresh="handleSearch" @search="handleSearch" />
        <container-panel>
            <template slot="title">
                风控模型列表
            </template>
            <!--      <a-button slot="button" type="primary"
                v-auth="RULE_ENGINE_ADD_RISK_CONTROL_BUTTON"
                @click="handleAdd">
        添加风控模型
      </a-button> -->
            <template slot="content" slot-scope="">
                <a-table :columns="columns"
                         :data-source="dataList"
                         :pagination="pagination"
                         :loading="loading"
                         row-key="id"
                         :scroll="scrollConfig"
                         @change="handleTableChange">
                    <template slot="state" slot-scope="text,record">
                        <a-switch :checked="Boolean(record.state)" @change="value => handleSwitch(value, record)" />
                    </template>
                    <template slot="action" slot-scope="text,action">
                        <a-button
                            v-auth="RULE_ENGINE_EDIT_RISK_CONTROL_BUTTON"
                            type="link"
                            :disabled="action.state===1"
                            @click="handleEdit(action)">
                            编辑
                        </a-button>
                    </template>
                </a-table>
            </template>
        </container-panel>

        <add-or-edit-risk-control-modal
            :mode="mode"
            :visible="visible"
            :info="info"
            @cancel="handleCancel"
            @success="handleSuccess" />
    </list-page-layout>
</template>

<script>
import { Button as AButton, Table as ATable, Switch as ASwitch } from 'ant-design-vue'
import {
    RULE_ENGINE_ADD_RISK_CONTROL_BUTTON,
    RULE_ENGINE_EDIT_RISK_CONTROL_BUTTON
} from '@constant/authEnum/ruleEngine'
import AddOrEditRiskControlModal from './AddOrEditRiskControlModal'
import ContainerPanel from '@components/ContainerPanel'
import ListPageLayout from '@layout/ListPageLayout'
import SearchPanel from './SearchPanel'

import { DEFAULT_PAGES_SIZE } from '@config'
import { ERROR_COMMIT_CLOSE, ERROR_COMMIT_OPEN } from '@constant/error'
import tableShowTotal from '@mixins/tableShowTotal'
import enumOperate from '@mixins/enumOperate'

import getRiskControlModelListService from '@service/ruleEngine/riskControlModel/getRiskControlModelListService'
import openOrCloseRiskControlModelService from '@service/ruleEngine/riskControlModel/openOrCloseRiskControlModelService'
import { scrollConfig } from '@constant/Const'

const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        align: 'left',
        width: 100,
        scopedSlots: {
            customRender: 'index'
        },
        key: 'index',
        customRender: (v, r, index) => ++index
    },
    {
        title: '业务类型',
        dataIndex: 'modelType',
        width: 200,
        scopedSlots: {
            customRender: 'modelType'
        },
        align: 'left',
        key: 'modelType',
        customRender: () => '保前风控业务'
    },
    {
        title: '风控模型名称',
        dataIndex: 'modelName',
        align: 'left',
        width: 360,
        key: 'modelName'
    },
    {
        title: '排序',
        dataIndex: 'sorting',
        align: 'left',
        width: 100,
        key: 'sorting'
    },
    {
        title: '是否启用',
        dataIndex: 'state',
        align: 'left',
        key: 'state',
        width: 200,
        scopedSlots: {
            customRender: 'state'
        }
    },
    {
        title: '操作',
        key: 'action',
        align: 'left',
        width: 200,
        scopedSlots: {
            customRender: 'action'
        }
    }
]
export default {
    name: 'RiskControlModel',
    components: {
        ListPageLayout,
        ContainerPanel,
        AddOrEditRiskControlModal,
        AButton,
        ATable,
        SearchPanel,
        ASwitch
    },
    mixins: [tableShowTotal, enumOperate],
    data() {
        return {
            scrollConfig,
            RULE_ENGINE_ADD_RISK_CONTROL_BUTTON,
            RULE_ENGINE_EDIT_RISK_CONTROL_BUTTON,
            columns,
            params: {},
            dataList: [],
            limit: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            loading: false,
            visible: false,
            info: {},
            mode: ''
        }
    },
    computed: {
        pagination() {
            const { total, limit, current, tableShowTotal } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        }
    },
    created() {
        this.getList()
    },
    methods: {
        handleAdd() {
            this.mode = 'create'
            this.visible = true
        },
        // 添加成功
        handleSuccess() {
            this.visible = false
            this.getList()
        },
        handleCancel() {
            this.visible = false
        },
        handleEdit(action) {
            this.mode = 'edit'
            this.info = action
            this.visible = true
        },
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        handleSearch(params) {
            this.params = params
            this.current = 1
            this.getList()
        },
        async handleSwitch(value, record) {
            try {
                let param = {
                    enable: +value,
                    id: record.id
                }
                await openOrCloseRiskControlModelService(param)
                let message = value ? ERROR_COMMIT_OPEN : ERROR_COMMIT_CLOSE
                this.$message.success(message)
                this.getList()
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            }
        },
        async getList() {
            try {
                this.loading = true
                const { current, limit, params } = this
                const param = {
                    current,
                    size: limit,
                    ...params
                }
                const result = await getRiskControlModelListService(param)
                this.dataList = result.records
                this.current = result.current
                this.total = result.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
